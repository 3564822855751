import React, { useState,useContext } from 'react';
import { Link,useNavigate,useLocation,useParams } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import { IconContext } from 'react-icons';
import {MdMenu,MdClose,MdHome} from "react-icons/md"
import {IoMdLogOut,IoMdNotifications,IoMdSettings} from "react-icons/io"
import {FaBars,FaHandPointRight,FaHandPointLeft} from "react-icons/fa"
import {BiMenuAltLeft,BiMenuAltRight} from "react-icons/bi"
import {DispatchContext,logout,StateContext} from "../Context/"
import styled from 'styled-components';
import img_profile from "../images/img_profile.jpg"
import { Form } from 'react-bootstrap';
import Lang from './Lang';
import ln from "../Translate/"
import SideBarPro from './SideBarPro';

const DivC = styled.div`
.div-right{
  /* background-color:red ; */
  height:100%;
}
.img_profile{
  overflow:hidden;
  border-radius:50%;
  box-shadow:0px 0px 2px 2px #FFF inset ;
}
.img_profile img{
  width:40px;
  height:40px;
  cursor:pointer;
  padding:1px;
  border-radius:50% ;
}
.btn-notif svg{
  font-size:24px
}
.btn-notif:focus{
  box-shadow:none;
}
.btn-notif:hover{
  background-color:#FFF5;
}
.btn-notif{
  border-radius:50%;
  width:40px;height:40px;
  text-align:center;
  padding:0;
}
.div-logout{
  position:absolute;
  top:calc(100% + 5px);
  right:5px;
  z-index:30;
  animation: tr .3s;
}
@keyframes tr{
  from{transform:scale(0);}
  to{transform:scale(1);}
}
.dropdown-item{
  cursor:pointer
}
.dropdown-item:active{
  background-color:#CCC;
  color:#222;
}
.menu-bars{
  /* border:2px solid #5552; */
  background-color: #4441;
  width: 40px;
  height: 40px;
  
}
.dir h6{
  margin: 0px;
font-size: 1rem;
line-height: 1.625;
font-family: "Roboto", "Helvetica", "Arial", sans-serif;
letter-spacing: 0.0075em;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
opacity: 1;
text-transform: capitalize;
vertical-align: unset;
text-decoration: none;
color: rgb(52, 71, 103);
font-weight: 700;
}
.dir p{
  margin: 0px;
font-family: "Roboto", "Helvetica", "Arial", sans-serif;
opacity: 1;
text-transform: capitalize;
vertical-align: unset;
font-size: 0.875rem;
letter-spacing: 0.02857em;
line-height: 0;
font-weight: 400;
color: rgb(52, 71, 103);
text-decoration: none;
}
@media screen and (max-width: 453px){
  .fname{display:none!important}
}
@media screen and (max-width: 368px){
  .menu-bars,.btn-notif{margin-right:0!important}
 
}
@media screen and (max-width: 338px){
  .btn-notif{display:none!important}
}
@media screen and (max-width: 298px){
  .searcht{display:none!important}
}
.searcht{
  margin-left: auto;
}
body[dir='rtl'] .searcht{
  margin-right: auto!important;
  margin-left: 0!important;
}
.btn-collaps svg{
  font-weight: bold;
  font-size:20px
}

`




const pathnames={
  "/clients":"Clients",
  "/":"Home",
  "/templates":"Templates",
  "/templates/new_template":"New Template",
  "/users":"Users",
  "/users/new_user":"New User",
}

const Navbar=(props)=> {
  const [sidebar, setSidebar] = useState(false);
  const [showList,setShowList]=useState(false);
  const { user ,lang} = useContext(StateContext);

  const lan=ln[lang];
  const navigate=useNavigate()
  const showSidebar = () =>{
    setSidebar(true);
  } 
  const hideSidebar = () =>{
    setSidebar(false);
  } 
  let location = useLocation();
  const dispatch = useContext(DispatchContext);
  const logoutUser=()=>{
    logout(dispatch);
    navigate("/")
  }
  window.onclick = function(event) {

    if (!event.target.matches('.imgP')&&showList) {
      
        setShowList(false)
    }
  }
  
  return (
    <DivC>
      <div className='navbar p-2 d-flex flex-row align-item-center'>
        <div className="btn-toggle" onClick={() => props.handleToggleSidebar(true)}>
          <FaBars />
        </div>
        <div className="btn-collaps ms-2" onClick={()=>props.handleCollapsed(!props.collapsed)}>
          {
            props.collapsed?(
              <BiMenuAltLeft />
            ):(
              <BiMenuAltRight />
            )
          }
        </div>
          <Lang/>
          
          <Form className=" navbar-title  flex-grow-1 " >
            <Form.Group className="searcht" controlId="search" style={{maxWidth:"200px"}}>
                <Form.Control type="text" placeholder={`${lan.SEARCH}`} />
            </Form.Group>
          </Form>
          <div className="div-right d-flex flex-wrap align-items-center ">

            <button className=" btn btn-notif me-3">
              <IoMdNotifications className="text-dark"/>
            </button>
            <div className='d-flex align-items-center me-3 fname' >
              {user.fname}
            </div>
            <div className="img_profile" onClick={()=>setShowList(!showList)}>
              <img src={img_profile} alt="" className="imgP"  />
              <ul className={`dropdown-menu div-logout ${showList?"show":""}`}>
                <li class="dropdown-item" onClick={logoutUser}><IoMdLogOut className="text-dark"/> {lan.LOGOUT}</li>
                <li class="dropdown-item" onClick={()=>navigate("/settings")}><IoMdSettings className="text-dark"/> {lan.SETTINGS}</li>
              </ul>
            </div>
               
          </div>
          
        </div>
        <div className="mb-4"></div>
    </DivC>
  );
}

export default Navbar;