import { useState, useEffect } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import SocialMediaService from "../Service/SocialMediaService"
import ApiConfig from "../config/ApiConfig"
import Switch from "react-switch";
const SocialMedia = (props) => {

    const { setColorThem, colorThem, data, setData } = props;
    const selectItem = (i, bool) => {
        setData(
            data.map((el, index) => {
                return { ...el, select: (i == index ? bool : el.select) };
            })
        )
    }
    const updateVal = (e) => {
        if (e.target.id === "background")
            setColorThem({ ...colorThem, background: e.target.value });
        else if (e.target.id === "text")
            setColorThem({ ...colorThem, text: e.target.value });
        else
            setData(
                data.map((el, index) => {
                    if (e.target.id === el.name)
                        return { ...el, value: e.target.value };
                    else
                        return el;
                })
            )
    }
    return (
        <Container>
            <Row>
                <Col>
                    <h5>Social Media</h5>
                    <hr />
                </Col>
            </Row>
            <Row>
                <Col>
                    {
                        data.map((el, key) => {
                            if (el.select)
                                return <div className="d-flex mt-1 align-items-center">
                                    <img src={`${ApiConfig.url + "social-media/logo/" + el.logo}`} alt="" className="me-2" width="30" height="30" />
                                    <input type="text" className='form-control' id={el.name} placeholder='Add link' value={el.value} onChange={updateVal} />
                                    <button className="btn btn-default" onClick={() => selectItem(key, false)}>X</button>
                                </div>
                            else
                                return "";
                        })
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    {
                        data.map((el, key) => {
                            if (!el.select)
                                return <>
                                    <img src={`${ApiConfig.url + "social-media/logo/" + el.logo}`} alt="" className="m-1" width="24" height="24" onClick={() => selectItem(key, true)} />
                                </>
                            else
                                return "";
                        })
                    }
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <h5>Color Theme</h5>
                    <hr />
                    <table>
                        <tr>
                            <td>
                                Background
                            </td>
                            <td>
                                <input type="color" className='form-control' id="background" style={{ width: "50px" }} value={colorThem.background} onChange={updateVal} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Text
                            </td>
                            <td>
                                <input type="color" className='form-control' id="text" style={{ width: "50px" }} value={colorThem.text} onChange={updateVal} />
                            </td>
                        </tr>
                    </table>


                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <h5>Delete Footer</h5>
                    <hr />
                    <Switch
                        onChange={(e) => props.updateDeleteFooter(!props.deleteFooter)}
                        checked={props.deleteFooter}
                        onColor="#5fc2fc"

                    />
                </Col>
            </Row>
        </Container>
    );
};

export default SocialMedia;