import axios from "axios";
import ApiConfig from "./ApiConfig.js";


const  http = axios.create({
  baseURL: ApiConfig.url,
  headers: {
    "Content-type":  ApiConfig.content_type
  }
})
const  httpUpload = axios.create({
  baseURL: ApiConfig.url,
  headers: {
    "Content-type":  "multipart/form-data"
  }
})

const  http3 = axios.create({
  baseURL: ApiConfig.url,
  headers: {
    "Content-type":  "application/json"
  }
})

const req={
  http,
  httpUpload,
  http3
}

export default req;