import React from 'react';
import { Link ,useLocation} from 'react-router-dom';
import ListContacts from "./ListContacts"
import Contacts from "./Contacts"
import AddContact from "./AddContact"
import AddList from './AddList';
import ImportCSV from './ImportCSV';
import {Container } from "react-bootstrap"
const Index = props => {
    const getView=(v)=>{
        switch(v){
            case "/clients" : return <ListContacts/>;
            case "/clients/add_list" : return <AddList update={false}/>;
            case "/clients/update_list" : return <AddList update={true}/>;
            case "/clients/contacts" : return <Contacts/>;
            case "/clients/contacts/add" : return <AddContact update={false}/>;
            case "/clients/contacts/update" : return <AddContact update={true}/>;
            case "/clients/contacts/import":return <ImportCSV/>;
        }
        return null;
    }


    const location=useLocation()
    
    return (
        <Container fluid="md" className="bx-sh mb-3 pb-3">
            {
                getView(location.pathname)
            }
        </Container>
    );
};



export default Index;