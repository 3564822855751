import {useState} from 'react';
import styled from "styled-components"
import logo from "../images/logo.svg"
const DivC=styled.div`
width:45px;
height:45px;
display: grid;
grid-template-columns: repeat(3,1fr);
grid-template-rows: repeat(3,1fr);
animation:rot 3.5s linear infinite;
/* filter: grayscale(100%); */
@keyframes rot{
    from{transform:rotate(0)}
    to{transform:rotate(360deg)}
}
    .c{
        background-image: url("${logo}");
        background-size: 300%;
    }
    .c:nth-child(2){background-position:top center;}
    .c:nth-child(3){background-position:top right;}
    .c:nth-child(4){background-position:left center;}
    .c:nth-child(5){
        background-position:center;}
    .c:nth-child(6){background-position:right center;}
    .c:nth-child(7){background-position:bottom left;}
    .c:nth-child(8){background-position:bottom center;}
    .c:nth-child(9){background-position:bottom right;}
    .c{
        /* background-color: #F80; */
    }
    .x0{
        animation: sc 2s linear infinite;
    }
    .x1{
        animation: sc 2s linear infinite;
        animation-delay:0.1s;
    }
    .x2{
        animation: sc 2s linear infinite;
        animation-delay:0.2s;
    }
    .x3{
        animation: sc 2s linear infinite;
        animation-delay:0.3s;
    }
    .x4{
        animation: sc 2s linear infinite;
        animation-delay:0.4s;
    }
    .x5{
        /*opacity: 0;*/
    }
    @keyframes sc{
        from{transform: scale(1);}
        10%{transform: scale(0.2);}
        20%{
            transform: scale(1);
            /* background-color: #F80; */
        }
        60%{
            /* background-color: red; */
        }
        to{
            transform: scale(1);
            /* background-color: #F80; */
        }
    }
`
function Loding(props) {
    return (
        <DivC>
            <div className="c x2"></div>
            <div className="c x3"></div>
            <div className="c x4"></div>

            <div className="c x1"></div>
            <div className="c x2"></div>
            <div className="c x3"></div>

            <div className="c x0"></div>
            <div className="c x1"></div>
            <div className="c x2"></div>
        </DivC>
    );
}

export default Loding;