import {useState,useContext} from 'react';
import styled from 'styled-components';
import fr from "../Translate/fr.svg"
import en from "../Translate/us.svg"
import ar from "../Translate/dz.svg"

import {DispatchContext,changeLang,StateContext} from "../Context/"

const DivC = styled.div`
position: relative;
.btn{
    border-radius:50%;
    box-shadow: none;
    font-weight: bold;
}
.list-lang{
    position: absolute;
    left: 0;
    top:50px;
    min-width: 100px;
}
.dropdown-item img{
    width:24px;
    height: 18px;
}
.ff{
    background-color: #F5F5F5;
}
`
const Lang = () => {
    const [click,setClick]=useState(false);
    

    const changeLangs=(v)=>{
        changeLang(dispatch,v);
        setLang(v);
        setClick(false);

    }
    const { lang } = useContext(StateContext);
    const [lans,setLang]=useState(lang);
    const dispatch = useContext(DispatchContext);
    return (
        <DivC onClick={()=>setClick(!click)}>
            <button className='btn btn-default' >
                {lans.toUpperCase()}
            </button>
            {
                
                <ul className={`dropdown-menu list-lang div-logout ${click?"show":""}`}>
                    <li class={`dropdown-item ${lans=="en"?"ff":""}`} onClick={()=>changeLangs("en")}><img src={en} className="me-3"/> EN</li>
                    <li class={`dropdown-item ${lans=="fr"?"ff":""}`} onClick={()=>changeLangs("fr")}><img src={fr} className="me-3"/> FR</li>
                </ul>

            }
        </DivC>
    );
};

export default Lang;