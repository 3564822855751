import {useState,useEffect,useContext} from 'react';
import Table from '../Table/Table';
import {RiSendPlaneFill,RiDeleteBinLine,RiAddLine} from "react-icons/ri"
import {MdOutlineModeEditOutline} from "react-icons/md"

import {ImStatsBars} from "react-icons/im"
import {IoMdOpen} from "react-icons/io"
import {StateContext} from "../Context/"
import ln from "../Translate/"
import TemplateService from "../Service/TemplateService"
import { Link ,useLocation,useNavigate} from 'react-router-dom';
import DataTable  from 'react-data-table-component';
import {TableStyleTemp} from "../Them/"
import Modal from "../Modal/"
import ClientService from "../Service/ClientService"
import ListEmailsSend from "./ListEmailsSend"
import {ToastStyle} from "../Them/"
import {toast} from "react-toastify"
import Tooltip from '../Them/Tootlip';
import 'react-toastify/dist/ReactToastify.css';
import {Container,Col,Row} from "react-bootstrap"
import ApiConfig from "../config/ApiConfig"
import Loding from '../Them/Loding';
function ListTemplates(props) {
    const [data,setData]=useState([])
    const navigate=useNavigate()
    const [dataEmails,setDataEmails]=useState(null)
    const { user,lang } = useContext(StateContext);
    const lan=ln[lang];
    const [listEmailsId,setListEmailsId]=useState([])
    const [showModalEmails,setShowModalEmails]=useState(false);
    const [pending, setPending] = useState(true);
    const columns = [
        {
            name: 'id',
            selector: row => row.id,
            grow:"none"
        },
        {
            name: 'Name',
            selector: row => row.name,
            grow:"none"
        },
        {
            name: 'Header',
            selector: row => row.header,
            grow:1,
            maxWidth:"300px"
        },
        {
            name: '',
            selector: row =><Container>
                <Row>
                    <Col>
                        <a href={`${ApiConfig.url}template/show_template.php?id=${row.id}`} target="_blank">
                            <button data-tip="preview" className='btn  text-dark btn-effect me-2' > 
                                <IoMdOpen/>
                                <Tooltip/>
                            </button>
                            
                            
                        </a>
                        <button className="btn btn-default  btn-effect me-2" onClick={()=>navigate("/templates/statistics",{state:row})}>
                        <ImStatsBars/>
                        </button>
                        <button data-tip="send" className='btn  text-dark btn-effect me-2' onClick={()=>navigate("/templates/send",{state:row})}> 
                            <RiSendPlaneFill/>
                            <Tooltip/>
                        </button>
                        <button data-tip="edit" className='btn  text-primary btn-effect me-2' onClick={()=>navigate("/templates/"+row.id,{state:row})}>
                            <MdOutlineModeEditOutline/>
                            <Tooltip/>
                        </button>
                        <button data-tip="delete" className='btn  text-danger btn-effect' >
                            <RiDeleteBinLine/>
                            <Tooltip/>
                        </button>
                    </Col>
                </Row>
            </Container>,
            allowOverflow:true,
            right:true
        }
    ]; 
    const getData =  () => {
        TemplateService.getAll(user.id)
        .then(res=>{

            setData(res.data.data) ;
            setPending(false)   
        }).catch(err=>{

        })
    }
    const getEmails=(_id)=>{
        showModalEmailsF();
        ClientService.getAll(user.id)
        .then(res=>{
            setDataEmails([user.id,_id,res.data])    
        }).catch(err=>{
            hideModalEmailsF()
            //toast.error(err.message,ToastStyle);
        });
    }
    const showModalEmailsF=()=>{
        setShowModalEmails(true)
    }
    const hideModalEmailsF=()=>{
        // console.log(dataEmails)
        setDataEmails(null)
        setShowModalEmails(false)
    }
    useEffect(()=>{
        getData()
    },[])
  
    return (
        <>
            <div className='row'>
                <div className='col-12 p-2 d-flex '>
                    
                    <Link to="/templates/new_template" className='btn btn-success '>
                        <span className='me-1'><RiAddLine/></span>
                        {lan.ADD_TEMPLATE}
                        
                    </Link>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={data}
                pagination
                customStyles={TableStyleTemp}
                sortable
                progressComponent={<Loding />}
                progressPending={pending} 
            />
            <Modal showModal={showModalEmails}>
                {
                    dataEmails==null?"loding":(
                        <ListEmailsSend
                            data={dataEmails}
                            hideModalEmailsF={hideModalEmailsF}
                        />
                    )
                }
                
            </Modal>
            
        </>
    );
}

export default ListTemplates;