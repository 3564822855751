import {useContext,useEffect,useState} from 'react'
import {StateContext,} from "../Context/";
import {useNavigate} from "react-router-dom"
import Navbar from '../Navbar/index';
import SideBarPro from '../Navbar/SideBarPro';
import "./style.scss"
const LayoutAdmin=({children})=> {

  const { islogin } = useContext(StateContext);
  const navigate=useNavigate();
  const [content,setContent]=useState(<div className="lodingD"></div>);
  const [loding,setLoding]=useState(true);
  const [toggled, setToggled] = useState(false);
  const handleToggleSidebar = (value) => {
    setToggled(value);
  };
  const [collapsed, setCollapsed] = useState(false);
  const handleCollapsed = (value) => {
    setCollapsed(value);
  };
  const div=(
    <div class="app">
      <SideBarPro
        collapsed={collapsed}
        toggled={toggled}
        rtl="ltr"
        handleToggleSidebar={handleToggleSidebar}/>
      <main>
        <Navbar 
          collapsed={collapsed}
          handleCollapsed={handleCollapsed}
          handleToggleSidebar={handleToggleSidebar}/>
        <div className="m-2">
          
          {children}
        </div>
      </main>
    </div>
  )

  useEffect(()=>{
    if(!islogin){
      navigate("/login")
    }else{
      setLoding(false)
    }
  },[])
  return <>{loding?content:div}</>
}
export default  LayoutAdmin
