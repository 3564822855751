import React from 'react';
import { Link ,useLocation} from 'react-router-dom';
import ListUsers from './ListUsers';
import NewUser from './NewUser';
import UserProfile from './UserProfile';
import {Container } from "react-bootstrap"
function Users(props) {


    const view={
        "/users":<ListUsers/>,
        "/users/new_user":<NewUser/>
    }
    const getView=(v)=>{
        switch(v){
            case "/users" : return <ListUsers/>;
            case "/users/new_user" : return <NewUser/>;
            default : return(<UserProfile/>);
        }
        return null;
    }


    const location=useLocation()
    
    return (
        <Container fluid="md" className="bx-sh mb-3 pb-3">
            {
                getView(location.pathname)
            }
        </Container>
    );
}

export default Users;