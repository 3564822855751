import req from "../config/http-common";
import ApiConfig from "../config/ApiConfig";
import parse from 'html-react-parser';


const  getList =  (id_admin) => {
  return req.http3.post("/contacts/index.php",{
    "session":true,
    "id_admin":id_admin,
    "get_list":true
  });
};
const getAllContact=(id_list)=>{
  return req.http3.post("/contacts/index.php",{
    "session":true,
    "id_list":id_list,
    "get_contacts":true
  });
}
const  addList =  (ob) => {
  const {id_admin,name_list}=ob
    return req.http3.post("/contacts/index.php",{
      "session":true,
      "id_admin":parseInt(id_admin),
      "add_list":true,
      "name":name_list
    });
};
const  addContact =  (ob) => {
  const {id_list,fname,lname,email,phone}=ob;
  return req.http3.post("/contacts/index.php",{
    "session":true,
    "id_list":id_list,
    "fname":fname,
    "lname":lname,
    "email":email,
    "phone":phone,
    "add_contact":true,
  });
};
const  updateContact =  (ob) => {
  const {id_con,fname,lname,email,phone}=ob;
  return req.http3.post("/contacts/index.php",{
    "session":true,
    "id_con":id_con,
    "fname":fname,
    "lname":lname,
    "email":email,
    "phone":phone,
    "update_contact":true,
  });
};
const  updateList =  (ob) => {
  const {id_list,name_list}=ob;
  return req.http3.post("/contacts/index.php",{
    "session":true,
    "id_list":id_list,
    "name":name_list,
    "update_list":true,
  });
};
const  deleteContact =  (id_con) => {

  return req.http3.post("/contacts/index.php",{
    "session":true,
    "id_con":id_con,
    "delete_contact":true,
  });
};
const  deleteList =  (id_list) => {

  return req.http3.post("/contacts/index.php",{
    "session":true,
    "id_list":id_list,
    "delete_list":true,
  });
};


const ContactService = {
    getList,
    addList,
    getAllContact,
    addContact,
    updateContact,
    deleteContact,
    updateList,
    deleteList
};

export default ContactService;