import {useState,useEffect,useContext} from 'react'
import {Form,Button,Container,Col,Row} from 'react-bootstrap'
import { Formik, Form as FormF, Field } from 'formik';
import * as Yup from 'yup';
import AdminService from '../Service/AdminService';
import styled from 'styled-components';
import Password from "../Views/Password" 
import {ToastStyle,Toast} from "../Views/Toast"

import {useNavigate} from "react-router-dom"
import {MdRefresh} from "react-icons/md"
import { 
    StateContext,
    loginUser,
    DispatchContext,
  } from "../Context/";



const DivC = styled.div`
position: relative!important;
padding-top:0;
*{
    font-family: Syne;
}
form{
    max-width:1000px;
    margin: auto;
    padding:20px 25px;
    border-radius:10px;
    background-color:#FFF ;
    position: relative;
}
.title{
    /* position: absolute; */
    left: 0px;
    right:0px;
    top: 0;
    height: 120px;
    /* transform: translateY(-50%); */
    line-height: 120px;
    color: #444;
    text-align: center;
    font-size: 30px;
    
}
.form-control{
    height: 44px;
    border-radius: 4px;;
}
.btn{
    /* height:50px ; */
    width:100% ;
    
}
.tr{
    animation:tr .3s
}
@keyframes tr{
    from{transform:translateX(-10px);opacity:0}
    to{transform:translateX(0px);opacity:1}
}
label {
    /* font-weight:400 ; */
    color:#444;
    /* text-transform:uppercase ; */
}
`
const userShema = Yup.object().shape({

    fname: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    lname: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string()
      .min(6, 'Too Short!')
      .max(40, 'Too Long!')
      .required('Required'),
    cpassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Password must match')
      .required('required'),
    phone:Yup.string()
    .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, {
      message: "Invalid number +12345678900"
    })
    .required('Required'),
});
const codeShema = Yup.object().shape({

    code: Yup.number("code is number")
      .required('Required'),

});






function Register() {
   

    const dispatch = useContext(DispatchContext);
    const [step,setStep]=useState(1);

    const navigate=useNavigate();

    const [codev,setCodev]=useState("");
    const [loding,setLoding]=useState(false);
    const [email,setEmail]=useState("");
    const [id_admin,setId_admin]=useState("");
    const cahngeNext=()=>{
        
        setStep(2)
    }
   
    const spanLoding=(
        <span className="spinner-border spinner-border-sm me-1"></span>
       )
    const signUp=(ob)=>{

        setLoding(true)
        AdminService.signUp(ob)
        .then(res=>{
            if(res.data.error===""){
                setEmail(ob.email);
                setId_admin(res.data.data);
                cahngeNext();
                
            }else{
                Toast.error(res.data.error,ToastStyle);
            }
            setLoding(false)
        }).catch(err=>{
            setLoding(false)
            Toast.error(err.message,ToastStyle);
        })
    }
    const signUpVerification=(ob)=>{

        setLoding(true)
        AdminService.signUpVerification(ob)
        .then(res=>{
            if(res.data.error===""){
               
                setStep(3)
                
            }else{
                Toast.error(res.data.error,ToastStyle);
            }
            setLoding(false)
        }).catch(err=>{
            setLoding(false)
            Toast.error(err.message,ToastStyle);
        })
    }
    const divLogin=(
        <DivC className="dddd ">
                <div className="title">
                    Sign Up
                </div>
                
                {
                step==1&&(
                <Container >

                <Formik
                    initialValues={{
                        fname: '',
                        lname: '',
                        email: '',
                        password:'',
                        cpassword:'',
                        phone:''
                    }}

                  validationSchema={userShema}
                onSubmit={(values,actions) => {
                    signUp(values)
                }}
            >
                {
                    (
                        { 
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors 
                        }
                    ) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        
                    <Row className="">
                            <Col lg={6} sm={12}>
                                <Form.Group controlId="fname">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="fname"
                                            value={values.fname}
                                            onChange={handleChange}
                                            isInvalid={!!errors.fname}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.fname}
                                        </Form.Control.Feedback>
                                </Form.Group>
                            </Col> 
                            <Col lg={6} sm={12}>
                                <Form.Group controlId="lname">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="lname"
                                            value={values.lname}
                                            onChange={handleChange}
                                            isInvalid={!!errors.lname}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.lname}
                                        </Form.Control.Feedback>
                                </Form.Group>
                            </Col> 
                            <Col lg={6} sm={12}>
                                <Form.Group controlId="email">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            isInvalid={!!errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={6} sm={12}>
                                <Form.Group controlId="password">
                                        <Form.Label> Password</Form.Label>

                                        <Form.Control
                                            type="password"
                                            name="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            isInvalid={!!errors.password}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.password}
                                        </Form.Control.Feedback>

                                    </Form.Group>
                            </Col>
                            <Col lg={6} sm={12}>
                                <Form.Group controlId="cpassword">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="cpassword"
                                            value={values.cpassword}
                                            onChange={handleChange}
                                            isInvalid={!!errors.cpassword}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.cpassword}
                                        </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6} sm={12}>
                                <Form.Group controlId="phone">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="phone"
                                        value={values.phone}
                                        onChange={handleChange}
                                        isInvalid={!!errors.phone}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.phone}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>


                    </Row>
                    <Row className="">
                        <Col className="d-flex justify-content-end">

                            <Button variant="warning" type="submit" style={{maxWidth:"100px"}} className='mt-2'>
                               
                                Next
                            </Button>
                            
                        </Col>
                    </Row>

                        </Form>

                 )}

            </Formik>




                </Container>
                )
            }
            {
            step==2&&   (
                <Container className="tr">


            <Formik
                    initialValues={{
                        code: '',
                    }}

                  validationSchema={codeShema}
                onSubmit={(values,actions) => {
                    signUpVerification({...values,id_admin})
                }}
            >
                {
                    (
                        { 
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors 
                        }
                    ) => (
                    <Form noValidate onSubmit={handleSubmit}>
                    <Row>
                        <Col className="">
                            <Form.Group controlId="code">
                                    <Form.Label>We sent a verification code to <strong>{email}</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="code"
                                        value={values.code}
                                        onChange={handleChange}
                                        isInvalid={!!errors.code}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.code}
                                    </Form.Control.Feedback>
                                </Form.Group>
                        </Col>
                    </Row>
                    <Row className="">
                        <Col className="d-flex justify-content-end">
                           
                            <Button variant="warning" type="submit" style={{maxWidth:"100px"}} className=''>
                                {loding ? spanLoding:""}
                                Submit
                            </Button>
                            
                        </Col>
                    </Row>
                    </Form>

                 )}

            </Formik>
                </Container>
                )
                }
            {
                step==3&&(
                    <Container>
                        <Row>
                            <Col>
                            <div className="alert alert-success">
                                <strong>Success Insert!</strong> Login.
                            </div>
                            </Col>
                        </Row>
                    </Container>
                )
            }   
      
        </DivC>
    );
    return <>
        {divLogin}
    </>
}


export default Register;
