import {useState} from 'react';
import {Form} from 'react-bootstrap'
import styled from "styled-components" 
import {AiOutlineEye,AiOutlineEyeInvisible}from "react-icons/ai"
const PasswordC=styled.div`
position: relative;
.btn{
    position: absolute;
    right:10px;
    cursor: pointer;
    width: 20px;
    height: 100%;
    top: 0;
}
`
function Password(props) {
    
    const [passV,setPassV]=useState(false)
    return (
        <PasswordC>
            <Form.Control 
                type={!passV?"password":"text"}
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                isInvalid={props.isInvalid}
            />
                {props.children}
            <div className="btn" onClick={()=>setPassV(!passV)}>
                {
                    passV?
                    <AiOutlineEyeInvisible/>
                    :
                    <AiOutlineEye/>
                                        
                }
            </div>
        </PasswordC>
    );
}

export default Password;