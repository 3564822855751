import {useState,useEffect,useContext,useRef } from 'react';
import {Button,Container,Row,Col} from 'react-bootstrap'
import {MdPersonOutline,MdOutlineEmail} from "react-icons/md"
import {HiOutlineTemplate} from "react-icons/hi"
import {FiSend} from "react-icons/fi"
import AdminService from "../Service/AdminService"
import {StateContext,DispatchContext} from "../Context/"
import ln from "../Translate/"
import Social from "./Social"
function Tools(props) {
    const [data,setData]=useState([]);
    const { user,lang } = useContext(StateContext);
    const lan=ln[lang];
    const dispatch = useContext(DispatchContext);
    useEffect(()=>{
      
    },[])

 
  
     
    return (
    <Container fluid="md" className="bx-sh mb-3 pb-3 pt-3">
        <Row>
            <Col sm={3}>
            <div class="list-group">
                <a href="#" class="list-group-item list-group-item-action active">Social Media</a>
                <a href="#" class="list-group-item list-group-item-action">Second item</a>
                <a href="#" class="list-group-item list-group-item-action">Third item</a>
            </div> 
            </Col>
            <Col sm={9}>
            <Social/>
            </Col>
        </Row>
    </Container>
        
    );
}

export default Tools;