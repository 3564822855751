import {useState} from 'react';
import {ToastStyle} from "../Them/"
import {Form,Button,Container,Row,Col} from 'react-bootstrap'
import { Link ,useLocation,useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {MdKeyboardBackspace} from "react-icons/md"
import ContactService from '../Service/ContactService';
import {toast} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
toast.configure()



const DivC = styled.div`
form{
    
    margin-top: 50px;
}
`
const tabN="0123456789";
function NewUser() {
    const [csv_file,setCsv_file]=useState("");
    const [loding,setLoding]=useState(false);
    const location=useLocation()
    const datar=location.state;
    const navigate=useNavigate();

    const updateVal=(e)=>{
        switch(e.target.id){
            case "csv_file":setCsv_file(e.target.files[0]);break;
            default:return;
        }
        readFile = function () {
            var reader = new FileReader();
            reader.onload = function () {
                document.getElementById('out').innerHTML = reader.result;
            };
            // start reading the file. When it is done, calls the onload event defined above.
            reader.readAsBinaryString(csv_file);
        };
    }
    const handleSubmitAdd=(e)=>{
        e.preventDefault();
        
        
        
        
       
    }
    const addContact=()=>{
        
        setLoding(true);
        ContactService.addContact().then(res=>{
            if(res.data.error==""){
                toast.success("Add success",ToastStyle);
            }else{
                toast.error(res.data.error,ToastStyle);
            }
            setLoding(false);
        }).catch(err=>{
            toast.error(err.message,ToastStyle);
            setLoding(false);
        })
    }
    const spanLoding=(
        <span className="spinner-border spinner-border-sm me-1"></span>
       )
    var readFile = function () {
           var reader = new FileReader();
           reader.onload = function () {
               document.getElementById('out').innerHTML = reader.result;
           };
           // start reading the file. When it is done, calls the onload event defined above.
           reader.readAsBinaryString(csv_file);
       };
   
   
    return (
        <>
        <div className='row p-2'>
            <div className='col-12'>
                <button className='btn btn-default float-end' onClick={()=>navigate(`/clients/contacts`,{state:datar})}>
                <span className='me-1'><MdKeyboardBackspace/></span>
                back
                </button>
            </div>
        </div>
    <DivC>
        <Form onSubmit={handleSubmitAdd}>
            <Container>
                <Row>
                    <Col sm={12}  >
                        <Form.Group className="mb-3" controlId="csv_file">
                            <Form.Label>Csv File Contacts</Form.Label>
                            <Form.Control type="file" placeholder="" name={"csv_file"}  onChange={updateVal}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <output id="out">
                            file contents will appear here
                        </output>
                    </Col>
                </Row>

                <Row>
                    <Col className="d-flex justify-content-end">
                        <Button variant="primary" type="submit" disabled={loding}>
                            {loding ? spanLoding:""}
                            upload
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Form>
    </DivC>
        </>
  )
}


export default NewUser;