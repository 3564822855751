



import Clients from './components/Clients';
import Home from './components/Home';
import Templates from './components/Templates';

import Settings from "./components/Settings"
import Users from "./components/Users"
import Upgrade from "./components/Upgrade"
import NewUser from "./components/Users/NewUser"
import UserProfile from "./components/Users/UserProfile"
import Tools from './components/Tools';
import React from "react";
import LayoutAdmin from './components/Layout';
import EmailProvider from "./components/Context/"
import WeGosend from "./components/WeGosend/"
import { BrowserRouter as Router,Route,Routes } from 'react-router-dom';

import NewTemplate from './components/Templates/NewTemplate';
class App extends React.Component {

  
  render(){
    return (
      <EmailProvider>
        <Router>
            <Routes>
              
              <Route path="/" element={<WeGosend />}>
                <Route path="/login" />
                <Route path="/register" />
              </Route>
              <Route path="/dashboard" element={<LayoutAdmin><Home /></LayoutAdmin>}/>
              <Route path="/clients" element={<LayoutAdmin><Clients /></LayoutAdmin>}>
                <Route path="/clients/contacts"/>
                <Route path="/clients/add_list"/>
                <Route path="/clients/update_list"/>
                <Route path="/clients/contacts/add"/>
                <Route path="/clients/contacts/update"/>
                <Route path="/clients/contacts/import"/>
              </Route>
              <Route path="/settings" element={<LayoutAdmin><Settings /></LayoutAdmin>}/>
              <Route path="/upgrade" element={<LayoutAdmin><Upgrade /></LayoutAdmin>}/>
              <Route path="/tools" element={<LayoutAdmin><Tools /></LayoutAdmin>}/>
              <Route path="/templates" element={<LayoutAdmin><Templates /></LayoutAdmin>}>
                <Route path="/templates/new_template" element={<LayoutAdmin><NewTemplate update={false}/></LayoutAdmin>}/>
                <Route path="/templates/:id" element={<LayoutAdmin><NewTemplate update={true}/></LayoutAdmin>}/>
                <Route path="/templates/send"/>
                <Route path="/templates/statistics"/>
              </Route>

              <Route path="/users" element={<LayoutAdmin><Users /></LayoutAdmin>}>
                <Route path="/users/new_user" element={<LayoutAdmin><NewUser /></LayoutAdmin>}/>
                <Route path="/users/:id" element={<LayoutAdmin><UserProfile /></LayoutAdmin>}/>
              </Route>
              
            </Routes>
        </Router>
      </EmailProvider>
    );
  }

}

export default App;
