import {useState} from 'react';
import {ToastStyle} from "../Them/"
import {Form,Button,Container,Row,Col} from 'react-bootstrap'
import { Link ,useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {MdKeyboardBackspace} from "react-icons/md"
import AdminService from '../Service/AdminService';
import {toast} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
toast.configure()



const DivC = styled.div`
form{
    
    margin-top: 50px;
}
`
const tabN="0123456789";
function NewUser() {
    const [fname,setFname]=useState("");
    const [lname,setLname]=useState("");
    const [email,setEmail]=useState("");
    const [phone,setPhone]=useState("");
    const [password,setPassword]=useState("");
    const [cpassword,setCpassword]=useState("");
    const [limit_template,setLimit_template]=useState("");
    const [limit_send,setLimit_send]=useState("");
    const [errors,setErrors]=useState({fn:0,ln:0,em:0,ph:0,ps:0,cps:0,lt:0,ls:0})
    const [loding,setLoding]=useState(false);

    const updateVal=(e)=>{
        switch(e.target.id){
            case "fname":setFname(e.target.value);break;
            case "lname":setLname(e.target.value);break;
            case "email":setEmail(e.target.value);break;
            case "phone":
                setPhone(e.target.value);break;
            case "password":setPassword(e.target.value);break;
            case "cpassword":setCpassword(e.target.value);break;
            case "limit_template":setLimit_template(e.target.value);break;
            case "limit_send":setLimit_send(e.target.value);break;
            default:return;
        }
    }
    const handleSubmitAdd=(e)=>{
        e.preventDefault();
        if(fname===""||lname===""||email===""||phone===""||password===""||cpassword===""||limit_template===""||limit_send===""){

            return;
        }
        
        
        
        addUser();
    }
    const addUser=()=>{
        var dataS={fname,lname,email,phone,password,limit_template,limit_send};
        setLoding(true)
        AdminService.addUser(dataS)
        .then(res=>{
            if(res.data.error===""){
                toast.success('Add success',ToastStyle);
                setFname("");
                setLname("");
                setEmail("");
                setPhone("");
                setPassword("");
                setCpassword("");
                setLimit_template("");
                setLimit_send("")
            }else{
                toast.error(res.data.error,ToastStyle);
            }
            setLoding(false)
        }).catch(err=>{
            setLoding(false)
            toast.error(err.message,ToastStyle);
        })
    }
    const spanLoding=(
        <span className="spinner-border spinner-border-sm me-1"></span>
       )
      
    return (
        <>
        <div className='row p-2'>
            <div className='col-12'>
                <Link to="/users" className='btn btn-default float-end'>
                <span className='me-1'><MdKeyboardBackspace/></span>
                back
                </Link>
            </div>
        </div>
    <DivC>
        <Form onSubmit={handleSubmitAdd}>
            <Container>
                <Row>
                    <Col sm={12}  lg={6}>
                        <Form.Group className="mb-3" controlId="fname">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" placeholder="" value={fname} onChange={updateVal}/>
                        </Form.Group>
                    </Col>
                    <Col sm={12} lg={6}>
                        <Form.Group className="mb-3" controlId="lname">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" placeholder="" value={lname} onChange={updateVal}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}  lg={6}>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="" value={email} onChange={updateVal}/>
                        </Form.Group>
                    </Col>
                    <Col sm={12} lg={6}>
                        <Form.Group className="mb-3" controlId="phone">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control type="text" placeholder="" value={phone} onChange={updateVal}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}  lg={6}>
                        <Form.Group className="mb-3" controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="text" placeholder="" value={password} onChange={updateVal}/>
                        </Form.Group>
                    </Col>
                    <Col sm={12} lg={6}>
                        <Form.Group className="mb-3" controlId="cpassword">
                            <Form.Label>Confime Password</Form.Label>
                            <Form.Control type="text" placeholder="" value={cpassword} onChange={updateVal}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}  lg={6}>
                        <Form.Group className="mb-3" controlId="limit_template">
                            <Form.Label>Limit Templates</Form.Label>
                            <Form.Control type="number" placeholder="" value={limit_template} onChange={updateVal}/>
                        </Form.Group>
                    </Col>
                    <Col sm={12} lg={6}>
                        <Form.Group className="mb-3" controlId="limit_send">
                            <Form.Label>Limit send Emails</Form.Label>
                            <Form.Control type="number" placeholder="" value={limit_send} onChange={updateVal}/>
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="primary" type="submit">
                    {loding ? spanLoding:""}
                    Add
                </Button>
            </Container>
        </Form>
    </DivC>
        </>
  )
}


export default NewUser;