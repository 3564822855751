import req from "../config/http-common";
import ApiConfig from "../config/ApiConfig";
import parse from 'html-react-parser';
const  getAll =  (id_admin) => {
 
  
  return req.http3.post("/template/index.php",{
    "session":true,
    "id_admin":id_admin,
    "get_all":true
  });
};

const  addTemplate =  (ob) => {

  const {name_temp,addition,header_temp,html_temp,data_temp,id_admin}=ob;



  return req.http3.post("/template/index.php",{
    "session":true,
    "name_temp":name_temp,
    "header_temp":header_temp,
    "html_temp":html_temp,
    "data_temp":data_temp,
    "addition":addition,
    "id_admin":id_admin,
    "add_temp":true
  });
};
const  updateTemplate =  (ob) => {
  const {name_temp,addition,header_temp,html_temp,data_temp,id_admin,id_temp}=ob;

  return req.http3.post("/template/index.php",{
    "session":true,
    "name_temp":name_temp,
    "header_temp":header_temp,
    "html_temp":html_temp,
    "data_temp":data_temp,
    "addition":addition,
    "id_admin":id_admin,
    "id_temp":id_temp,
    "update_temp":true
  });
};

const  sendEmails =  (ob) => {
  const {id_temp,list_ids,date}=ob;
  return req.http3.post("/template/index.php",{
    "session":true,
    "id_temp":id_temp,
    "list_ids":list_ids,
    "date_send":date,
    "send_mails":true,
  });
};
const  getInfo =  (id_temp) => {
  return req.http3.post("/template/index.php",{
    "session":true,
    "id_temp":id_temp,
    "get_info":true,
  });
};
const  deleteTemplate =  (id,id_admin) => {
  var data=new FormData();
  data.append("id",id);
  data.append("id_admin",id_admin);
  data.append("session",true);
  return req.http.post("/template/delete_template.php",data);
};



const TemplateService = {
  getAll,
  addTemplate,
  deleteTemplate,
  sendEmails,
  getInfo,
  updateTemplate
};

export default TemplateService;