import {useState,useContext,useRef,useEffect} from 'react';
import {ToastStyle} from "../Them/"
import {Form,Button,Container,Row,Col} from 'react-bootstrap'
import { Link ,useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {MdKeyboardBackspace} from "react-icons/md"
import {toast} from "react-toastify"
import {StateContext} from "../Context/"
import {MdOutlineDesignServices} from "react-icons/md"
import Stepper from 'react-stepper-horizontal';
import TemplateService from "../Service/TemplateService"
import ln from "../Translate/"
import 'react-toastify/dist/ReactToastify.css';
import ApiConfig from "../config/ApiConfig"
import EmailEditor from 'react-email-editor';
import SocialMedia from "./SocialMedia"
import Select from 'react-select';
import SocialMediaService from "../Service/SocialMediaService"
toast.configure()

const DivC = styled.div`
.bt{
    min-width: 100px;
}
`

const NewTemplate=({update})=> {

    const location=useLocation()
    const [datar,setDatar]=useState(location.state);
    
    const emailEditorRef = useRef(null);
    const refShow=useRef(null);
    const [step,setStep]=useState(0);
    const [loding,setLoding]=useState(false);
    const { user,lang } = useContext(StateContext);
    const lan=ln[lang];
    const [importTemp,setImportTemp]=useState(null);
    const [temp_html,setTemp_html]=useState("");
    const [temp_json,setTemp_json]=useState("");
    const [temp_name,setTemp_name]=useState("");
    const [temp_header,setTemp_header]=useState("");
    const [data,setData]=useState([]);
    const [dataTemps,setDataTemps]=useState([]);
    const [colorThem,setColorThem]=useState({background:"#BBB",text:"#000"});


    
    useEffect(()=>{
        
        if(update){
            
            setColorThem(JSON.parse(datar.addition).colorThem)
            setTemp_html("")
            setTemp_json(JSON.parse(datar.data))
            setTemp_name(datar.name)
            setTemp_header(datar.header)
            setData(JSON.parse(datar.addition).social)
        }else{
            getData()
        }
        TemplateService.getAll(user.id).
        then(res=>{
            setDataTemps(res.data.data) ;
        })
    },[])
    useEffect(()=>{
        console.log(importTemp)
        if(importTemp)
        setTimeout(()=>{setTemp_json(JSON.parse(importTemp.temp.data))},10)
    },[importTemp])
    const getData=()=>{
        SocialMediaService.getAll().
        then(res=>{
            setData(res.data.map(el=>{
                return {name:el.name,link:el.link,logo:el.logo,select:false,value:"https://"};
            }));
        })

        

    }

    const updateVal=(e)=>{
        switch(e.target.id){
            case "temp_name":setTemp_name(e.target.value);break;
            case "temp_header":setTemp_header(e.target.value);break;
            default:return;
        }
    }
    const handleSubmitAdd=(e)=>{
        e.preventDefault();

        let obhtml= new DOMParser().parseFromString(temp_html, "text/html");
        obhtml.querySelectorAll(".u-row-container:last-child .u-row:last-child")[0].innerHTML+=footer_temp;
        let d=obhtml.querySelectorAll(".u-row a");
        
        for(let i=0;i!=d.length;i++){
                                            
            d[i].href=ApiConfig.url+"template/redirect.php?link="+d[i].href+"&id=ID_CLIENT_REMPLACE"
        }

        if(!update)
            addTemplate(obhtml.firstElementChild.outerHTML.trim())
        else
            updateTemplate(obhtml.firstElementChild.outerHTML.trim())
    }
    const clear=()=>{
        setTemp_html("")
        setTemp_json("")
        setTemp_name("")
        setTemp_header("")
    }
    const addTemplate=(obhtml)=>{
        
        let addition={social:data,colorThem:colorThem}
        setLoding(true)
        let ob={
            name_temp:temp_name,
            header_temp:temp_header,
            html_temp:obhtml,
            data_temp:JSON.stringify(temp_json),
            addition:JSON.stringify(addition),
            id_admin:user.id
        }
        TemplateService.addTemplate(ob).then(res=>{
            if(res.data.error==""){
                clear()
                setStep(0)
                toast.success("Add success",ToastStyle);
            }else{
                toast.error(res.data.error,ToastStyle);
            }
            setLoding(false)

        }).catch(err=>{
            toast.error(err.message,ToastStyle);
            setLoding(false)
        })
    }
    const updateTemplate=(obhtml)=>{
       

        let addition={social:data,colorThem:colorThem}
        setLoding(true)
        let ob={
            name_temp:temp_name,
            header_temp:temp_header,
            html_temp:obhtml,
            data_temp:JSON.stringify(temp_json),
            addition:JSON.stringify(addition),
            id_temp:datar.id,
            id_admin:user.id
        }
        TemplateService.updateTemplate(ob).then(res=>{
            if(res.data.error==""){
                clear()
                setStep(4)
                toast.success("Update success",ToastStyle);
            }else{
                toast.error(res.data.error,ToastStyle);
            }
            setLoding(false)

        }).catch(err=>{
            toast.error(err.message,ToastStyle);
            setLoding(false)
        })
    }

    const exportHtml = () => {
        emailEditorRef.current.editor.exportHtml((data) => {
          const { design, html } = data;
          setTemp_html(html);
          setTemp_json(design);
        });
    };
    const getSocial=()=>{
        let list="";
        for(let i=0;i!=data.length;i++)
        if(data[i].select)
        list+='<a href="'+data[i].value+'" alt="f" style="display:inline-block;color:#000;margin:4px" ><img src="'+ApiConfig.url+"social-media/logo/"+data[i].logo+'" width="40" height="40"></a>' 
        return list;
    }
    
    const [footer_temp,setFooter_temp]=useState('<div style="padding: 20px;background-color: '+colorThem.background+'"><div style="display: flex;justify-content: center;"><img width="1" height="1" src="'+ApiConfig.url+'images/show.php?id=ID_CLIENT_REMPLACE" alt=""/>'+getSocial()+'</div><p style="text-align: center;color:'+colorThem.text+'!important">&#174; wegosend 2022<br><br><img src="'+ApiConfig.url+'images/logow.png" width="100px"><br><a href="" style="color:'+colorThem.text+'!important">Unsubscribe instantly</a></p></div>');
    const [deleteFooter,setDeleteFooter]=useState(false);
    const updateDeleteFooter=(v)=>{
        setFooter_temp(v?"":'<div style="padding: 20px;background-color: '+colorThem.background+'"><div style="display: flex;justify-content: center;"><img width="1" height="1" src="'+ApiConfig.url+'images/show.php?id=ID_CLIENT_REMPLACE" alt=""/>'+getSocial()+'</div><p style="text-align: center;color:'+colorThem.text+'!important">&#174; wegosend 2022<br><br><img src="'+ApiConfig.url+'images/logow.png" width="100px"><br><a href="" style="color:'+colorThem.text+'!important">Unsubscribe instantly</a></p></div>');
        setDeleteFooter(v);
    }
    const onReady = () => {
        
        emailEditorRef.current.editor.addEventListener('design:updated', (data)=> {
            exportHtml();
           
        })
        if(temp_html!==""||update||importTemp!=null){
            if (emailEditorRef.current && emailEditorRef.current.editor){
                emailEditorRef.current.editor.loadDesign(temp_json);
                exportHtml();
            }
        }
    };
    const changeStep=async (etap,v)=>{
        if(v==3){
           
        }
        setStep(v)
    }
    
    
    const spanLoding=(
        <span className="spinner-border spinner-border-sm me-1"></span>
       )
    return (
        <>
        <div className='row p-1'>
            <div className='col-12'>
                <Link to="/templates" className='btn btn-default float-end'>
                <span className='me-1'><MdKeyboardBackspace/></span>
                back
                </Link>
            </div>
        </div>
    <DivC className='' style={{minHeight:"500px"}}>
    <div>
      <Stepper steps={[
                {title: 'Setup '},
                {title: 'Design'},
                {title:'Additions'},
                {title: 'save'},
            ]} 
            size={50}
           activeStep={ step}
           completeColor="#fca313 "
           completeTitleColor="#444"

           completeBarColor="#fca313"
           defaultBarColor="#AAA"
           defaultBarStyle="dotted"
           activeColor="#616161"
           circleFontSize={24}

        />
        <Container className="mt-3">
            <Form onSubmit={handleSubmitAdd}>
            {
                step==0&&(
                <Row>
                    <Col lg={6} md={12}>
                        <Form.Group className="mb-3" controlId="temp_name">
                            <Form.Label>Name Template</Form.Label>
                            <Form.Control type="text" placeholder="Enter name" value={temp_name} onChange={updateVal}/>
                        </Form.Group>
                    </Col>
                    <Col lg={6} md={12}>
                        <Form.Group className="mb-3" controlId="temp_header">
                            <Form.Label>Header</Form.Label>
                            <Form.Control type="text" placeholder="Enter Header" value={temp_header} onChange={updateVal}/>
                        </Form.Group>
                    </Col>
                    <Col sm={12}>
                        <Form.Group className="mb-3" controlId="temp_header">
                            <Form.Label>import from</Form.Label>
                            
                            <Select
                                value={importTemp}
                                defaultValue={importTemp}
                                onChange={setImportTemp}
                                closeMenuOnSelect={true}
                                isSearchable={true}
                                isMulti={false}
                                options={dataTemps.map(el => {
                                    return { label: el.name, value: el.name, temp: el }
                                })}
                            />
                            {/* <Form.Control type="text" placeholder="Enter Header" value={temp_header} onChange={updateVal}/> */}
                        </Form.Group>
                    </Col>
                    <Col className='mt-4 d-flex justify-content-end'>
                        <button class="btn btn-primary bt" disabled={temp_name==""||temp_header==""} onClick={()=>changeStep("next",1)}>Next</button>
                    </Col>
                </Row>
                )
            }
            {
                step==1&&(
                <Row>
                    <Col>
                        <EmailEditor
                            ref={emailEditorRef}
                            onReady={onReady}
                            className="border"
                        />
                    </Col>
                    <Col className='mt-4 d-flex justify-content-end'>
                        
                        <button class="btn btn-primary bt" onClick={()=>changeStep("prev",0)}>Previous</button>
                        <button class="btn btn-primary ms-3 bt" disabled={temp_html==""} onClick={()=>{changeStep("next",2)}}>Next</button>
                    </Col>
                </Row>
                )
            }
             {
                step===2&&(
                <Row>
                    <Col sm={4}>
                        <SocialMedia 
                            setData={setData} 
                            data={data}
                            colorThem={colorThem}
                            updateDeleteFooter={updateDeleteFooter}
                            deleteFooter={deleteFooter}
                            setColorThem={setColorThem}/>
                    </Col>
                    <Col sm={8}>
                        <div ref={refShow} id="refShow">
                            {
                              
                                !setTimeout(()=>{
                                    refShow.current.innerHTML=temp_html;
                                    
                                       
                                        let ob=document.getElementsByClassName("u-row");
                                        ob[ob.length-1].innerHTML+=footer_temp
                                      
                                },10)&&""
                              
                            }
                        </div>
                    </Col>
                    <Col sm={12} className='mt-4 d-flex justify-content-end'>
                        
                        <button class="btn btn-primary bt" onClick={()=>changeStep("prev",1)}>Previous</button>
                        <button class="btn btn-primary ms-3 bt"  onClick={()=>{changeStep("next",3)}}>Next</button>
                    </Col>
                </Row>
                )
            }
                
            {
                step==3&&(
                <Row>
                    <Col className='mt-4 d-flex justify-content-end'>
                        <button class="btn btn-primary bt" onClick={()=>changeStep("prev",2)}>Previous</button>
                        <Button variant="primary" type="submit" className='ms-3 bt' disabled={loding}>
                            {loding ? spanLoding:""}
                            {
                                update?"Update":"Submit"
                            }
                            
                        </Button>
                    </Col>
                </Row>
                )
            }
             {
                step==4&&(
                <Row>
                    <Col className=''>
                    <div class="alert alert-success">
                        <strong>Success!</strong> Edited successfully.
                    </div>
                    </Col>
                </Row>
                )
            }
                
                
            </Form>
            
        </Container>
    </div>
    </DivC>
    </>
  )
}


export default NewTemplate;