import {useEffect, useState} from 'react';
import {ToastStyle} from "../Them/"
import {Button,Container,Row,Col} from 'react-bootstrap'
import { Link ,useLocation,useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {MdKeyboardBackspace} from "react-icons/md"
import ContactService from '../Service/ContactService';
import {toast} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import { TextField }  from "../Them/TextField"
toast.configure()



const DivC = styled.div`
form{
    
    margin-top: 50px;
}
`
function AddContact({update}) {
    const [loding,setLoding]=useState(false);
    const [isSubmit,setIsSubmit]=useState(false);
    const location=useLocation()
    const data=location.state;
    const navigate=useNavigate();
    const validate = Yup.object({
        fname: Yup.string()
        .required('First Name is Required')
        .matches(/^[a-zA-Z]+$/,"Number and special character is not  allowed")
        .min(3, 'Miminum 3 charagter needed')
        .max(50, 'Maximum 50 charagter needed'),
        lname: Yup.string()
        .required('Last Name is Required')
        .matches(/^[a-zA-Z]+$/,"Number and special character is not  allowed")
        .min(3, 'Miminum 3 charagter needed')
        .max(50, 'Maximum 50 charagter needed'),
        email: Yup.string()
          .email('Email is invalid')
          .required('Required'),
        phone: Yup.number()
        .required('99-9999-9999 format is Required'),
      })
    const clear=(actions)=>{
        setIsSubmit(true)
        setTimeout(() => {
            
            actions.setSubmitting(false)
            actions.resetForm();
           
        }, 1000);
        
    }
    const addContact=(ob,actions)=>{
        
        setLoding(true);
        ContactService.addContact(ob).then(res=>{
            if(res.data.error==""){
                toast.success("Add success",ToastStyle);
                clear(actions)
            }else{
                toast.error(res.data.error,ToastStyle);
            }
            setLoding(false);
        }).catch(err=>{
            toast.error(err.message,ToastStyle);
            setLoding(false);
        })
    }
    const updateContact=(ob,actions)=>{
        
        setLoding(true);
        ContactService.updateContact(ob).then(res=>{
            if(res.data.error==""){
                toast.success("update success",ToastStyle);
                clear(actions)
            }else{
                toast.error(res.data.error,ToastStyle);
            }
            setLoding(false);
        }).catch(err=>{
            toast.error(err.message,ToastStyle);
            setLoding(false);
        })
    }
    const spanLoding=(
        <span className="spinner-border spinner-border-sm me-1"></span>
       )
      
    return (
        <>
        <div className='row p-2'>
            <div className='col-12'>
                <button className='btn btn-default float-end' onClick={()=>navigate(`/clients/contacts`,{state:update?data.list:data})}>
                <span className='me-1'><MdKeyboardBackspace/></span>
                back
                </button>
            </div>
        </div>
    <DivC>
    <Formik
      initialValues={{
        fname: isSubmit?"":(!update?"":data.con.fname),
        lname: isSubmit?"":(!update?"":data.con.lname),
        email: isSubmit?"":(!update?"":data.con.email),
        phone: isSubmit?"":(!update?"":data.con.phone),
      }}
      validationSchema={validate}
      onSubmit={(values,actions )=> {
        let {fname,lname,email,phone}=values;
        let ob;
        if(!update)
            ob={
                "id_list":data.id_list,
                fname,
                lname,
                email,
                phone
            }
        else 
            ob={
                id_con:data.con.id_con,
                fname,
                lname,
                email,
                phone
            }
        if(!update)
            addContact(ob,actions);
        else
            updateContact(ob,actions);

      }}
    >
      {formik => (
        <Form onSubmit={formik.handleSubmit}>
            <Container>
                <Row>
                    <Col sm={12}  lg={6}>
                        <TextField  label="First Name" name="fname" type="text"  />
                    </Col>
                    <Col sm={12} lg={6}>
                        <TextField  label="Last Name" name="lname" type="text"  />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}  lg={6}>
                        <TextField  label="Email" name="email" type="email"  />
                    </Col>
                    <Col sm={12} lg={6}>
                        <TextField  label="Phone" name="phone" type="tel"  /> 
                    </Col>
                </Row>
             

                <Row>
                    <Col className="d-flex justify-content-end">
                        <Button variant="primary" type="submit" disabled={loding}>
                            {loding ? spanLoding:""}
                            {update?"Update":"Add"}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Form>
         )}
         </Formik>
    </DivC>
        </>
  )
}


export default AddContact;