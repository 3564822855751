
import React from 'react';
import styled from 'styled-components';
import { Container,Col,Row } from 'react-bootstrap';
import image from "../images/ss.gif"
import Upgrade from "../Upgrade/"
const DivC=styled.div`
*{
    font-family: Syne;
}
.ex h1{
    font-size: 50px!important;
    font-weight: 900!important;
    line-height: 50px;
    
    color:#010101
}
.ex p{
    font-size: 22px;
    font-weight: 600;
    color:#c3c3c3
}
.ex img{
    width:100%
}
.btn{
    background-color: #fca311;
    border-radius: 50px;
    border:none;
    color:#fff8ed;
    box-shadow:none!important;
    font-size:20px;
    width:auto;
}
.ro{
    padding: 0px 20px;
}
.ro *{
    font-family: Syne;
}

.content{
    background: white;
    min-height: 100vh;
    background: #FFF;
}
@media screen and (max-width: 995px){
    .ex{
        padding:1em
    }
}
@media screen and (max-width: 557px){
    .btn{
        font-size:16px;
    }
    .ex h1{
        font-size: 44px!important;
    }
    .ex p{
        font-size: 18px;
    }
}
`

const WeGosend = () => {
    
    return (
        <DivC className='row ro'>
                        
            <Col className="d-flex flex-column justify-content-center ex p-5 pe-5 ps-6" sm={12} lg={5} md={12} >
                <h1 className='ps-6'>Prenez les commandes.</h1>
                <p className=' mt-2'>
                Libérez votre potentiel.<br/> 
                Tous les outils marketing et vente 
                pour faire décoller votre business.
                </p>
                <button className="btn btn-info mt-3 align-self-start ps-4 pe-4">Lancez-vous. C'est gratuit </button>
            </Col>
            <Col sm={12} lg={7}  md={12} className="ex">
                <img src={image} alt="" />
            </Col>
            <Upgrade header={"no"}/>
        </DivC>
    );
};

export default WeGosend;


