import {StateContext} from "../Context/"
import { useContext } from "react";

const Get=()=>{
    const { lang } = useContext(StateContext);
    return lang;
}
const en={
    dir:"ltr",
    LOGOUT:"Logout",
    SETTINGS:"Settings",
    HOME:"Home",
    USERS:"Users",
    CLIENTS:"Clients",
    TEMPLATES:"Templates",
    SEARCH:"Searche",
    ADD_USER:"Add user",
    FIRST_NAME:"First Name",
    LAST_NAME:"Last Name",
    EMAIL:"Email",
    EMAILS_SEND:"Emails Sent",
    PHONE:"Phone",
    STATE:"State",
    PASSWORD:"Password",
    CONFIRM_PASSWORD:"Confirme Password",
    LIMIT_TEMPLATES:"Limit Templates",
    LIMIT_SEND_EMAILS:"Limit send Emails",
    ADD:"Add",
    BACK:"Back",
    IMPORT_CSV_FILE:"Import CSV File",
    CSV_FILE:"CSV File",
    ADD_CLIENT:"Add Client",
    CANCEL:"Cancel",
    DELETE:"Delete",
    UPLOAD:"Upload",
    ADD_TEMPLATE:"Add Template",
    HEADER:"Header",
    CONTENT:"Content",
    LOGO:"Logo",
    LINK_TEXT:"Link Text",
    LINK_ADDRESS:"Link Address",
    COLOR:"Color",


}
const fr={
    dir:"ltr",
    LOGOUT:"Se déconnecter",
    SETTINGS:"Paramètres",
    HOME:"Accueil",
    EMAILS_SEND:"E-mails Envoyés",
    USERS:"Utilisateurs",
    CLIENTS:"Clients",
    TEMPLATES:"Modèles",
    SEARCH:"Rechercher",
    ADD_USER:"Add user",
    FIRST_NAME:"First Name",
    LAST_NAME:"Last Name",
    EMAIL:"Email",
    PHONE:"Phone",
    STATE:"State",
    PASSWORD:"Password",
    CONFIRM_PASSWORD:"Confirme Password",
    LIMIT_TEMPLATES:"Limit Templates",
    LIMIT_SEND_EMAILS:"Limit send Emails",
    ADD:"Add",
    BACK:"Back",
    IMPORT_CSV_FILE:"Import CSV File",
    CSV_FILE:"CSV File",
    ADD_CLIENT:"Add Client",
    CANCEL:"Cancel",
    DELETE:"Delete",

    UPLOAD:"télécharger",
    ADD_TEMPLATE:"Ajouter un Modèle",
    HEADER:"Entête",
    CONTENT:"Contenu",
    LOGO:"Logo",
    LINK_TEXT:"Texte du lien",
    LINK_ADDRESS:"Adresse du lien",
    COLOR:"Couleur"

}
const ln={
    "en":en,
    "fr":fr
}
export default ln;