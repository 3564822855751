
import {useState} from 'react';
import styled from 'styled-components';
import logo from "../images/logos.png"
import { Container,Col,Row,Offcanvas } from 'react-bootstrap';
import Main from "./Main"
import Login from "../Login"
import Register from '../Register';
import {SiFacebook ,SiInstagram,SiTwitter} from "react-icons/si"
import { Link ,useLocation} from 'react-router-dom';
import {FaBars} from "react-icons/fa"

const DivC=styled.div`

    background: white;
    font-family: "Syne", Arial,  sans-serif !important;
    min-height: 100vh;

a{
    text-decoration: none;
    color: #b2b2b2;
}



.footer p{
    font-size: 14px;
    color:#888
}
.footer *{
    font-family: Syne;
}
.social a {
    font-size:22px
}
.content{
    background: white;
    
    background: #FFF;
    min-height:100%;
}
.offcanvas{
    max-width: 85%!important;
}
`
const Header=styled.div`
    height: 80px;
    background-color: #f5f5f5;
    

.logo img{
    height:40px
}
.ul{
    margin: 0;
    padding: 0;
    list-style: none;
}

.ul li{
    padding: 5px 40px;
    font-size: 16px;
    font-weight: 600;
    font-family: Syne;
    color:#444;
    border-radius:60px;
    cursor: pointer;
}
.ul li a{
    color:#444;
    font-family: Syne;
}
.ul li.active,.ul li:hover{
    color:#fff8ed!important;
    background-color: #fca311;
}
.ul li:hover a,.ul li.active a{
    color:#fff8ed!important;
}
.c{
    padding-top: 40px;
}
.btn-menu{
    box-shadow: none;
    display:none;
}
@media screen and (max-width: 587px){
    .res{
        display: none;
    }
}
@media screen and (max-width: 557px){
    justify-content: space-between !important;
    padding-left: 20px;
    padding-right:20px ;
    .imagelogo{
        height:30px;
    }
    .pages{
        display:none
    }
    .btn-menu{
        box-shadow: none;
        display:initial;
    }
}

`
const WeGosend = () => {
    const [menuOpen,setMenuOpen]=useState(false);
    const getView=(v)=>{
        switch(v){
            case "/" : return <Main/>;
            case "/login" : return <Login/>;
            case "/register" : return <Register/>;
        }
        return null;
    }
    const location=useLocation()
    return (
        <DivC className="d-flex flex-column">
        
            <Header className="d-flex align-items-center justify-content-around">
                
                <Link to="/" className="">
                    <img src={logo} alt="" className="imagelogo" height="40"/>
                </Link>
                <div className='me-5 res'>

                </div>
                <div className='pages '>
                    <ul className='d-flex ul'>
                        
                        <li className={`me-1 ${location.pathname=="/login"?"active":""}`}>
                        <Link to="/login">
                            Se connecter
                        </Link>
                            
                        </li>
                        <li className={`${location.pathname=="/register"?"active":""}`}>
                            <Link to="/register">
                            S'inscrire
                            </Link>
                        </li>
                    </ul>
                </div>
                <button className="btn btn-default btn-menu" onClick={()=>setMenuOpen(true)}>
                    <FaBars />
                </button>
            </Header>
            
                <Container className="content  flex-grow-1 d-flex flex-column">
                    <div className="flex-grow-1">
                    {
                        getView(location.pathname)
                    }
                    </div>
                    <hr className="mb-2"/>
                    <Row className="footer d-flex align-items-center pb-4">
                        <Col md={4} sm={12}>
                            <p className="p-0 m-0">© 2022 wegosend. All rights reserved </p>
                        </Col>
                        <Col md={4} sm={12}>
                            <p className='text-center p-0 m-0'>Mentions légales, Politique de confidentialité</p>
                        </Col>
                        <Col md={4} sm={12} className='d-flex justify-content-end social align-items-center'>
                            <a href="https://www.facebook.com/wegosend" className="me-3">
                                <SiFacebook />
                            </a>
                            <a href="#" className="me-3">
                                <SiInstagram/>
                            </a>
                            <a href="#">
                                <SiTwitter/>
                            </a>
                        </Col>
                    </Row>
                </Container>
            <Offcanvas show={menuOpen} onHide={()=>setMenuOpen(false)}>
                <Offcanvas.Body className='canvas'>
                    <ul className='d-flex ul'>
                        
                        <li className={`me-1 ${location.pathname=="/login"?"active":""}`}>
                        <Link to="/login">
                            Se connecter
                        </Link>
                            
                        </li>
                        <li className={`${location.pathname=="/register"?"active":""}`}>
                            <Link to="/register">
                            S'inscrire
                            </Link>
                        </li>
                    </ul>
                </Offcanvas.Body>
            </Offcanvas>    
          
            
        </DivC>
    );
};

export default WeGosend;


