import {useState,useEffect,useContext} from 'react';
import {Button,Container,Row,Col,Card} from 'react-bootstrap'
import {MdPersonOutline,MdOutlineEmail,MdNotificationsOff,MdKeyboardBackspace} from "react-icons/md"
import {HiOutlineTemplate} from "react-icons/hi"
import {FiSend} from "react-icons/fi"
import {FaEye} from "react-icons/fa"
import {GiClick} from "react-icons/gi"
import TemplateService from "../Service/TemplateService"
import {StateContext,DispatchContext} from "../Context/"
import ln from "../Translate/"
import styled from 'styled-components';
import Stat from "./Stat";
import BoxInfo from "../Home/BoxInfo"
import { Link ,useLocation} from 'react-router-dom';



const DivC = styled.div`

.card{
    border:none;
    min-height: 100px;
    margin-top: 14px;
    border-radius: 0.75rem;;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
}
.card hr{
    background:linear-gradient(45deg,transparent ,#555,transparent)
}
.logo{
    position:absolute;
    top:10px;
    left:10px;
    height: 50px;
    width:50px;
    font-size:30px;
    border-radius:10px;
    color:#fcf9f4;
    background-color: #616161;
}
.info{
    display: flex;
    -moz-box-pack: justify;
    justify-content: space-between;
    padding-top: 8px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    background: transparent none repeat scroll 0% 0%;
    color: rgb(52, 71, 103);
    box-shadow: none;
}

.info h4{
    font-weight: bold;
    font-size:  1.5rem;
    letter-spacing: 0.00735em;
    color:rgb(52, 71, 103);
}
.info p{
    color: rgb(123, 128, 154);
    font-size:0.875rem;
    font-weight: 300!important;
}
`
function Home(props) {
    const [data,setData]=useState();
    const { user,lang } = useContext(StateContext);
    const lan=ln[lang];
    const location=useLocation();
    const [table,setTable]=useState({items:[]})
    const datar=location.state;
    console.log(datar)
    const dispatch = useContext(DispatchContext);
    useEffect(()=>{
        getData();
    },[])

    const getData=()=>{
        TemplateService.getInfo(datar.id)
        .then(res=>{
            setData(res.data.data)
            setTable(res.data.data.totalMails)
        }).catch(err=>{

        })
    }
    const getEmails=(v)=>{
        setTable(v);
    }
    return (
        <DivC>
            <div className='row p-1'>
                <div className='col-12'>
                    <Link to="/templates" className='btn btn-default float-end'>
                    <span className='me-1'><MdKeyboardBackspace/></span>
                    back
                    </Link>
                </div>
            </div>
        {
            data==null?"loding":(
                <>
            <Row>
               <Col className="card m-2 p-3">
                   <div class="logo d-flex align-items-center justify-content-center" >
                        <MdOutlineEmail/>
                   </div>
                    <div className='d-flex flex-column info'>
                        <p className="text-end m-0 ">Total Mails</p>
                        <h4 className="p-0 text-end m-0">{data.totalMails.total}</h4>
                    </div>
                    <hr />
                    <button className="btn btn-default" onClick={()=>getEmails(data.totalMails)}>show</button>
               </Col>
               <Col className="card m-2 p-3">
                    <div class="logo d-flex align-items-center justify-content-center" >
                        <FiSend/>
                   </div>
                    <div className='d-flex flex-column info'>
                        <p className="text-end m-0 ">Total Send</p>
                        <h4 className="p-0 text-end m-0">{data.totalSend.total}</h4>
                    </div>
                    <hr />
                    <button className="btn btn-default" onClick={()=>getEmails(data.totalSend)}>show</button>
               </Col>
               <Col className="card m-2 p-3">
                    <div class="logo d-flex align-items-center justify-content-center" >
                        <FaEye/>
                   </div>
                    <div className='d-flex flex-column info'>
                        <p className="text-end m-0 ">Total open</p>
                        <h4 className="p-0 text-end m-0">{data.totalOpen.total}</h4>
                    </div>
                    <hr />
                    <button className="btn btn-default" onClick={()=>getEmails(data.totalOpen)}>show</button>
               </Col>
               <Col className="card m-2 p-3">
                    <div class="logo d-flex align-items-center justify-content-center" >
                        <GiClick/>
                   </div>
                    <div className='d-flex flex-column info'>
                        <p className="text-end m-0 ">Total click</p>
                        <h4 className="p-0 text-end m-0">{data.totalClick.total}</h4>
                    </div>
                    <hr />
                    <button className="btn btn-default" onClick={()=>getEmails(data.totalClick)}>show</button>
               </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <table className="table table-borderless">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                             table.items.map((el,key)=>{
                                 return <tr key={key}>
                                     <td>{el.id_con}</td>
                                     <td>{el.email}</td>
                                 </tr>
                             }) 
                        }
                        </tbody>
                    </table>
                
                </Col> 
            </Row>
            </>
            )
        }
        </DivC>
        
    );
}

export default Home;





// {
//     data==0?"loding":(
//         <>
//         <Row>
//             <Stat
//                 title="readings"
//                 icon={<FaEye/>}
//                 color="#08F"
//                 number={data.read_tc}
//             />
//            <Stat
//                 title="clickers"
//                 icon={<GiClick/>}
//                 color="#125012"
//                 number={data.click_tc}
//             />
//             <Stat
//                 title="unsubscribed"
//                 icon={<MdNotificationsOff/>}
//                 color="#c72509"
//                 number={data.unsubscribed}
//             />
//         </Row>
//         </>
//     )
// }