import {useState,useEffect} from 'react'
import TemplateService from "../Service/TemplateService"
import {ToastStyle} from "../Them/"
import {toast} from "react-toastify"
import DatePicker from "react-datepicker";
import {Form} from 'react-bootstrap'
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import{MdDateRange} from "react-icons/md"


toast.configure()
const  ListEmailsSend=({data,hideModalEmailsF})=> {


    const [dataEmails,setDataEmails]=useState(
        data[2].map(el=>{
            return {id:el.id,email:el.email,checked:false}
        })
    );
    const [date,setDate]=useState(new Date());
    const [loding,setLoding]=useState(false);
    const [select,setSelect]=useState(false);
    const selectAll=()=>{
        setSelect(!select);
        setDataEmails(
            dataEmails.map(el=>{
                return{id:el.id,email:el.email,checked:!select}
            })
        )
    }
    const handleChange=(id)=>{
        setDataEmails(
            dataEmails.map(el=>{
                return{id:el.id,email:el.email,checked:id==el.id?!el.checked:el.checked}
            })
        )
        
    }
    const sendEmails=()=>{
        var ids=[]
        for(let i=0;i!=dataEmails.length;i++){
            if(dataEmails[i].checked)
            ids.push(dataEmails[i].id);
        }
        send(ids);
    }
    const spanLoding=(
        <span className="spinner-border spinner-border-sm me-1"></span>
       )
    const send=(data_emails)=>{
        setLoding(true)
        TemplateService.sendEmails(data_emails,data[1],data[0],date.toISOString().replace("T"," ").slice(0,19))
        .then(res=>{
            setLoding(false);
            if(res.data.error==""){
                hideModalEmailsF();
                toast.success('Send success',ToastStyle);
            }else{
                toast.error(res.data.error,ToastStyle);
            }
        }).catch(err=>{
            setLoding(false)
            toast.error(err.message,ToastStyle);
        })
    }
    const getItemSelected=()=>{    
        let s=0;
        for(let i=0;i!=dataEmails.length;i++){
            s=s+(dataEmails[i].checked?1:0)
        }    
        return s;
    }
    useEffect(()=>{
        
    },[])
    return (
        <>
        <div className='container'>
        {
            dataEmails==null?"loding":(
            <>
                <div className='row p-2'>
                    <div className='col-12 d-flex justify-content-between'>
                        <span class="badge bg-light text-dark d-flex align-items-center">{getItemSelected()} items selected</span>
                        <button className={`btn btn-${select?"primary":"default"} btn-sm`} onClick={selectAll}>Select all</button>
                    </div>
                </div>
                <ul className="list-group" style={{maxHeight:"300px",overflowX:"hidden"}}>
                    {
                        dataEmails.map((el)=>{
                            return <div className={`list-group-item d-flex justify-content-between ${!el.checked?"text-secondary":""}`}>
                                {el.email}
                                <input type="checkbox" checked={el.checked} className="custom-control-input float-right" onChange={(e)=>handleChange(el.id)}/>
                            </div>
                        })
                    }
                </ul>
                <Form.Group className="mb-3 mt-2"  >
                    <Form.Label> <MdDateRange/> Date send this template</Form.Label>
                    <DatePicker
                        id="date" 
                        className='form-control' 
                        showTimeSelect 
                        min={ Date.now()} 
                        minDate={Date.now()}
                        dateFormat="yyyy-MM-dd HH:mm:00" 
                        selected={date} 
                        onChange={(dates) => {setDate(dates)}}
                        timeFormat="HH:mm"
                        
                        />
                </Form.Group>
            </>
            )
        }
        </div>
            <div className='col-12 d-flex mt-2 '>
                <button className='btn btn-primary flex-grow-1 me-2' onClick={sendEmails} >
                    {loding ? spanLoding:""}
                    Send
                </button>
                <button className='btn btn-danger flex-grow-1' onClick={hideModalEmailsF}>Cancel</button>
            </div>
    </>
    )
}
export default ListEmailsSend