import {useState} from 'react';
import {Button,Form,Container,Row,Col} from 'react-bootstrap'
import SocialMediaService from '../Service/SocialMediaService';
import {ToastStyle} from "../Them/"
import {toast} from "react-toastify"
toast.configure()

const FormAddSocial = ({getData}) => {
    const [name,setName]=useState("");
    const [link,setLink]=useState("");
    const [logo,setLogo]=useState("");
    const updateVal=(e)=>{
        switch(e.target.id){
            case "name":setName(e.target.value);break;
            case "link":setLink(e.target.value);break;
            case "logo":setLogo(e.target.files[0]);break;
            default:return;
        }
    }
    
    const handleSubmit=(e)=>{
        e.preventDefault();
        if(name===""||link===""||logo===""){
            return;
        }
        if(logo===null){
            return
        }
        var ext=logo.name.split(".").reverse()[0];
        if(ext!="png"){
            alert("upload just png images")
            return;
        }
        addSocial();
    }
    const addSocial=()=>{
        var dataF=new FormData();
        
        dataF.append("name",name);
        dataF.append("link",link);
        dataF.append("session",true);
        dataF.append("logo",logo);
        SocialMediaService.addSocial(dataF).
        then(res=>{
            if(res.data.error==""){
                
                toast.success("Add Success",ToastStyle);
                setName("")
                setLink("")
                getData();
            }else{
                toast.error(res.data.error,ToastStyle);
            }
        }).catch(err=>{
            toast.error(err.message,ToastStyle);
        })
    }
    return (
        <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" value={name} onChange={updateVal} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="link">
                        <Form.Label>Link</Form.Label>
                        <Form.Control type="link" value={link} onChange={updateVal} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="logo">
                        <Form.Label>Logo</Form.Label>
                        <Form.Control type="file" onChange={updateVal} />
                    </Form.Group>
                    <Button variant="primary" type="submit" className='mt-2'>
                        Submit
                    </Button>
        </Form> 
    );
};

export default FormAddSocial;