import {useState,useEffect,useContext} from 'react';
import {Button,Container,Row,Col} from 'react-bootstrap'
import BoxInfo from './BoxInfo';
import {MdPersonOutline,MdOutlineEmail,MdNotificationsOff} from "react-icons/md"
import {HiOutlineTemplate} from "react-icons/hi"
import {FiSend} from "react-icons/fi"
import {FaEye} from "react-icons/fa"
import {GiClick} from "react-icons/gi"
import AdminService from "../Service/AdminService"
import {StateContext,DispatchContext} from "../Context/"
import ln from "../Translate/"
import styled from 'styled-components';
import Stat from "./Stat";
import Loding from '../Them/Loding';
import {Toast,ToastStyle} from "../Views/Toast"

const DivC = styled.div`
.stat{
    background-color:#FFF;
}
hr{
    background:linear-gradient(45deg,transparent ,#555,transparent)
}
`
function Home(props) {
    const [data,setData]=useState([]);
    const { user,lang } = useContext(StateContext);
    const lan=ln[lang];
    const dispatch = useContext(DispatchContext);
    useEffect(()=>{
        getData();
    },[])

    const getData=()=>{
        AdminService.getInfo(user.id)
        .then(res=>{
            if(res.data.error=="")
                setData(res.data.data);
            else
                Toast.error(res.data.error,ToastStyle);
        }).catch(err=>{
            Toast.error(err.message,ToastStyle);
        })
    }
    return (
        <DivC>
            <Container style={{backgroundColor:"transparent",marginTop:"50px"}}>
                
                {
                    data==0?(
                        <div className="p-4 d-flex justify-content-center">
                            <Loding/>
                        </div>
                    ):(
                        <>
                        <Row>
                            {
                                user.type==1?(
                                    <Col lg={3} md={6} sm={12}>
                                        <BoxInfo
                                            title={lan.USERS}
                                            number={data.users}
                                            color="rgba(0, 0, 0, 0) linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232)) repeat scroll 0% 0%"
                                            newNumber="25"
                                            icon={<MdPersonOutline/>}
                                        />
                                    </Col>
                                ):""
                            }
                            <Col lg={3} md={6} sm={12}>
                                <BoxInfo
                                    title={lan.CLIENTS}
                                    number={data.contacts}
                                    color="rgba(0, 0, 0, 0) linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25)) repeat scroll 0% 0%"
                                    newNumber="140"
                                    icon={<MdOutlineEmail/>}
                                />
                            </Col>
                            <Col lg={3} md={6} sm={12}>
                                <BoxInfo
                                    title={lan.TEMPLATES}
                                    number={data.temps}
                                    color="rgba(0, 0, 0, 0) linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96)) repeat scroll 0% 0%"
                                    newNumber="16"
                                    icon={<HiOutlineTemplate/>}
                                />
                            </Col>
                            <Col lg={3} md={6} sm={12}>
                                <BoxInfo
                                    title={lan.EMAILS_SEND}
                                    number={data.emails}
                                    color="rgba(0, 0, 0, 0) linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71)) repeat scroll 0% 0%"
                                    newNumber="219"
                                    icon={<FiSend/>}
                                />
                            </Col>
                        </Row>
                        <hr/>
                        <Row className="mt-3">
                            <Col lg={3} md={6} sm={12}>
                                <BoxInfo
                                    title="readings"
                                    number={data.read_tc}
                                    color="#08F"
                                    newNumber=""
                                    icon={<FaEye/>}
                                />
                            </Col>

                            <Col lg={3} md={6} sm={12}>
                                <BoxInfo
                                    title="clickers"
                                    number={data.click_tc}
                                    color="#125012"
                                    newNumber=""
                                    icon={<GiClick/>}
                                />
                            </Col>

                            <Col lg={3} md={6} sm={12}>
                                <BoxInfo
                                    title="unsubscribed"
                                    number={data.unsubscribed}
                                    color="#c72509"
                                    newNumber=""
                                    icon={<MdNotificationsOff/>}
                                />
                            </Col>


                            
                          
                            
                            
                        </Row>
                        </>
                    )
                }
            </Container>
        </DivC>
        
    );
}

export default Home;