import {useState,useEffect} from 'react';
import { Link ,useLocation,useNavigate} from 'react-router-dom';
import DataTable from 'react-data-table-component';
import {TableStyle} from "../Them/"
import {RiAddLine,RiDeleteBinLine} from "react-icons/ri"
import {MdReadMore,MdDelete} from "react-icons/md"
import AdminService from '../Service/AdminService';
import {Container,Col,Row} from "react-bootstrap"
import {ToastStyle} from "../Them/"
import {toast} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

import styled from 'styled-components';

const DivC = styled.div`
    .cy{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        box-shadow: 0px 0px 2px 0px #0005 inset;
    }
`

function ListUsers(props) {
    const location=useLocation();
    const navigate=useNavigate();
    const [data,setData]=useState([])
    const [columns] =useState([
        
        {
            name: 'First Name',
            selector: row => row.fname,
        },
        {
            name: 'Last Name',
            selector: row => row.lname,
        },
        {
            name: 'Email',
            selector: row => row.email,
        },
        {
            name: 'Phone',
            selector: row => row.phone,
        },
        {
            name: 'State',
            selector: row => <div className={`cy ${row.state==1?"bg-success":"bg-danger"}`}></div>,
        },
        {
            name: '',
            selector: row =><Container>
                <Row>
                    <Col>
                        <button className='btn btn-light text-dark btn-effect me-1' onClick={()=>navigate("/users/"+row.id,{state:row})}><MdReadMore/></button>
                        <button className='btn btn-light text-danger btn-effect' ><RiDeleteBinLine/></button>
                    </Col>
                </Row>
            </Container>,
            right:true,
            allowOverflow:true
        }
    ]); 


    const getData =  () => {
        AdminService.getUsers()
        .then(res=>{
            if(res.data.error==""){
                setData(res.data.data) 
            }else{
                toast.error(res.data.error,ToastStyle);
            }
               
        }).catch(err=>{
            toast.error(err.message,ToastStyle);
        })
    }
    useEffect(() => {
        getData();
    }, []);

    return (
        <DivC>
            <div className='row p-2'>
                <div className='col-12 d-flex'>
                    
                    <div className='flex-grow-1'></div>
                    <Link to="/users/new_user" className='btn btn-success '>
                    <span className='me-1'><RiAddLine/></span>
                    add user
                    </Link>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={data}
                pagination
                customStyles={TableStyle}
                sortable
            />
        </DivC>
    );
}

export default ListUsers;