import React from 'react';

function Modal({showModal,children}) {
    
    return (
        showModal&&(
            <div className='Mmodal_out d-flex justify-content-center z-index-100'>
                <div className='Mmodal_in align-self-center bg-white p-3 border'>
                    {children}
                </div>
            </div>
        )
    );
}

export default Modal;