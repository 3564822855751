import {useEffect, useState} from 'react'
import { Link ,useParams,useLocation} from 'react-router-dom';
import {MdKeyboardBackspace} from "react-icons/md"
import AdminService from "../Service/AdminService"
import {Form,Button,Container,Row,Col} from 'react-bootstrap'
import styled from 'styled-components';
import img_profile from "../images/img_profile.jpg"
const DivC = styled.div`
.image-profile{
    width: 74px;
    height:74px;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.12) 0rem 0.3125rem 0.625rem 0rem;
}
.image-profile img{
    width: 100%;
    height:100%;
}
.infoph h5{
    font-size: 1.25rem;
line-height: 1.375;
font-family: "Roboto", "Helvetica", "Arial", sans-serif;
letter-spacing: 0em;
opacity: 1;
text-transform: none;
vertical-align: unset;
text-decoration: none;
color: rgb(52, 71, 103);
font-weight: 600;
margin:0
}
.infoph p{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
font-size: 0.875rem;
line-height: 1.5;
letter-spacing: 0.02857em;
opacity: 1;
text-transform: none;
vertical-align: unset;
text-decoration: none;
color: rgb(123, 128, 154);
font-weight: 400;
margin:0;
}
`

const  UserProfile=(props)=> {
    const params=useParams();
    const location=useLocation()
    const [data,setData]=useState(location.state);
    useEffect(()=>{
        
    },[])

    

  return (
    <DivC>
        <div className='row p-1'>
            <div className='col-12'>
                <Link to="/users" className='btn btn-default float-end'>
                <span className='me-1'><MdKeyboardBackspace/></span>
                back
                </Link>
            </div>
        </div>
        <Container>
            <Row>
                <Col className="d-flex align-items-center">
                    <div className='image-profile'>
                        <img src={img_profile} alt="" />
                    </div>
                    <div className="infoph ms-3">
                        <h5>{data.fname+" "+data.lname}</h5>
                        <p>{data.email+" / "+data.phone}</p>
                    </div>
                </Col>
            </Row>
        </Container>
    </DivC>
  )
}
export default UserProfile;
// UserProfile :{params.id}
//             {
//                 data==null?"":(
//                     <Row>
//                         <Col>
//                             <strong>First Name</strong>
//                             <p>{data.fname}</p>
//                         </Col>
//                         <Col>
//                             <strong>Last Name</strong>
//                             <p>{data.lname}</p>
//                         </Col>
//                     </Row>
//                 )
//             }