import {useState,useEffect,useContext} from 'react';
import {RiFileListLine,RiDeleteBinLine,RiAddLine} from "react-icons/ri"
import {MdOutlineModeEditOutline,MdKeyboardBackspace} from "react-icons/md"
import {TiThListOutline} from "react-icons/ti"

import DataTable  from 'react-data-table-component';
import {Link, useLocation,useNavigate} from "react-router-dom"
import {TableStyleTemp} from "../Them/"
import ContactService from "../Service/ContactService"
import {StateContext} from "../Context/"
import {Container,Col,Row} from "react-bootstrap"
import Modal from "../Modal/"
import {ToastStyle} from "../Them/"
import Tooltip from '../Them/Tootlip';
import {toast} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
const Contacts = () => {
    const [pending, setPending] = useState(true);
    const [data,setData]=useState([])
    const [itemD,setItemD]=useState(null)
    const [showModalDelete,setShowModalDelete]=useState(false);
    const { user,lang } = useContext(StateContext);
    const location=useLocation()
    const datar=location.state;
    const [lodingUpload,setLodingUpload]=useState(false)
    const spanLoding=(
    <span className="spinner-border spinner-border-sm me-1"></span>
   )
    console.log(datar)
    const navigate=useNavigate()
    const getData =  () => {
        ContactService.getAllContact(datar.id_list)
        .then(res=>{

            setData(res.data.data) ;
            setPending(false)   
        }).catch(err=>{
            setPending(false)  
        })
    }
    useEffect(()=>{
        getData()
    },[])
    const columns = [
        
        {
            name: 'First Name',
            selector: row => row.fname,
            grow:"1"
        },
        {
            name: 'Last Name',
            selector: row => row.lname,
            grow:"1"
        },
        {
            name: 'Email',
            selector: row => row.email,
            grow:"1"
        },
        {
            name: 'Phone',
            selector: row => row.phone,
            grow:"1"
        },
        {
            name: '',
            selector: row =><Container>
                <Row>
                    <Col>
                        
                        <button data-tip="edit" className='btn btn-light text-primary btn-effect me-2' onClick={()=>navigate("/clients/contacts/update",{state:{list:datar,con:row}})}>
                            <MdOutlineModeEditOutline/>
                            <Tooltip/>
                        </button>
                        <button data-tip="delete" className='btn btn-light text-danger btn-effect' onClick={()=>showModalDeleteF(row.id_con)}>
                            <RiDeleteBinLine/>
                            <Tooltip/>
                        </button>
                    </Col>
                </Row>
            </Container>,
            allowOverflow:true,
            right:true
        }
    ]

    const showModalDeleteF=(id)=>{
        setItemD(id)
        setShowModalDelete(true);
    }
    const hideModalDeleteF=()=>{
        setLodingUpload(false)
        setItemD(null)
        setShowModalDelete(false);
    }
    const deleteContact =  () => {
        setLodingUpload(true)
        ContactService.deleteContact(itemD)
        .then(res=>{
            if(res.data.error==""){
                toast.success('Delete success',ToastStyle);
                getData()
            }else{
                toast.error(res.data.error,ToastStyle);
            }
            hideModalDeleteF()
        }).catch(err=>{
            hideModalDeleteF()
            toast.error(err.message,ToastStyle);
        })
    }

    return (
        <>
             <div className='row'>
                <div className='col-12 p-2 d-flex '>
                    <button className='btn btn-danger me-2' onClick={()=>navigate("/clients/contacts/import",{state:datar})}>
                        import csv 
                    </button>
                    <button onClick={()=>navigate("/clients/contacts/add",{state:datar})} className='btn btn-success '>
                        <span className='me-1'><RiAddLine/></span>
                        Add Contact 
                        
                    </button>
                   <div className='flex-grow-1'></div>
                   <Link to="/clients" className='btn btn-default float-end'>
                        <span className='me-1'><MdKeyboardBackspace/></span>
                    back
                    </Link>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={data}
                pagination
                customStyles={TableStyleTemp}
                sortable
                progressPending={pending} 
            />
            <Modal showModal={showModalDelete}>
                <h4 className=" p-3 text-center border-bottom mb-4">Are you sure to delete this item?</h4>
                <div className='col-sm-12 d-flex'>
                    <button className='btn btn-danger flex-grow-1 me-4' onClick={deleteContact}>
                        {
                                lodingUpload&&(
                                    spanLoding 
                                )
                        }
                        Yes
                    </button>
                    <button className='btn btn-primary flex-grow-1' onClick={hideModalDeleteF}>Cancel</button>
                </div> 
            </Modal>
        </>
    );

};

export default Contacts;