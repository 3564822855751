import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
const Tootlip=()=> {
    return (
        <ReactTooltip 
        effect="solid" 
        backgroundColor="#0c1c31"
        claassName="p-0"/>
    );
}

export default Tootlip;