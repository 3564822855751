import req from "../config/http-common";
import ApiConfig from "../config/ApiConfig";

const  getAll =  () => {
 
  var data=new FormData();
  data.append("session",true);
  return req.http.post("/social-media/get_all.php",data);
};

const  addSocial =  (data) => {
  return req.httpUpload.post("/social-media/add.php",data);
};


const SocialMediaService = {
  getAll,
  addSocial
};

export default SocialMediaService;