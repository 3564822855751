import {useState} from 'react';
import {ToastStyle} from "../Them/"
import {Form,Button,Container,Row,Col} from 'react-bootstrap'
import { Link ,useLocation,useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {MdKeyboardBackspace} from "react-icons/md"
import ContactService from '../Service/ContactService';
import {toast} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
toast.configure()



const DivC = styled.div`
form{
    
    margin-top: 50px;
}
`
const tabN="0123456789";
function AddList({update}) {
    
    const [loding,setLoding]=useState(false);
    const location=useLocation()
    const datar=location.state;
    const [name_list,setName_list]=useState(!update?{val:"",error:""}:{val:datar.name_list,error:""});
    const navigate=useNavigate();

    const updateVal=(e)=>{
        switch(e.target.id){
            case "name_list":setName_list({error:"",val:e.target.value});break;
            default:return;
        }
    }
    const handleSubmitAdd=(e)=>{
        e.preventDefault();
        if(name_list.val===""){
           
            setName_list({...name_list,error:"Empty input"})
            return;
        }
        
        let ob ;
        if(!update){
            ob={
                id_admin:datar.id,
                "name_list":name_list.val
            }
            addList(ob);
        }
        else{
            ob={
                id_list:datar.id_list,
                "name_list":name_list.val
            }
            updateList(ob);
        }
        
    }
    const addList=(ob)=>{
        setLoding(true);
        ContactService.addList(ob).then(res=>{
            if(res.data.error==""){
                toast.success("Add success",ToastStyle);
                setName_list({val:"",error:""});
            }else{
                toast.error(res.data.error,ToastStyle);
            }
            setLoding(false);
        }).catch(err=>{
            toast.error(err.message,ToastStyle);
            setLoding(false);
        })
    }
    const updateList=(ob)=>{
        setLoding(true);
        ContactService.updateList(ob).then(res=>{
            if(res.data.error==""){
                toast.success("update success",ToastStyle);
                setName_list({val:"",error:""});
            }else{
                toast.error(res.data.error,ToastStyle);
            }
            setLoding(false);
        }).catch(err=>{
            toast.error(err.message,ToastStyle);
            setLoding(false);
        })
    }
    const spanLoding=(
        <span className="spinner-border spinner-border-sm me-1"></span>
       )
      
    return (
        <>
        <div className='row p-2'>
            <div className='col-12'>
                <button className='btn btn-default float-end' onClick={()=>navigate(`/clients`)}>
                <span className='me-1'><MdKeyboardBackspace/></span>
                back
                </button>
            </div>
        </div>
    <DivC>
        <Form onSubmit={handleSubmitAdd}>
            <Container>
                <Row>
                    <Col sm={12}  >
                        <Form.Group className="mb-3" controlId="name_list">
                            <Form.Label>Name List</Form.Label>
                            <Form.Control 
                            type="text" 
                            placeholder="" 
                            value={name_list.val} 
                            onChange={updateVal}
                            isInvalid={name_list.error!==""}
                            />
                                <Form.Control.Feedback type="invalid">
                                    {name_list.error}
                                </Form.Control.Feedback>

                        </Form.Group>
                    </Col>
                </Row>
               
             

                <Row>
                    <Col className="d-flex justify-content-end">
                        <Button variant="primary" type="submit" disabled={loding}>
                            {loding ? spanLoding:""}
                            {update?"Update":"Add"}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Form>
    </DivC>
        </>
  )
}


export default AddList;