import {useState,useEffect,useContext} from 'react'
import {Form,Button,Container} from 'react-bootstrap'
import AdminService from '../Service/AdminService';
import styled from 'styled-components';
import {ToastStyle} from "../Them/"
import {toast} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import {useNavigate} from "react-router-dom"
import logo from "../images/logo.svg"
import Loding from '../Them/Loding';
import { 
    StateContext,
    loginUser,
    DispatchContext,
  } from "../Context/";

toast.configure()

const DivC = styled.div`
*{
    font-family: Syne;
}
form{
    max-width:400px;
    margin: auto;
    padding:20px 25px;
    border-radius:10px;
    background-color:#FFF ;
    position: relative;
}
.title{
  
    height: 120px;
    line-height: 120px;
    color: #444;
    text-align: center;
    font-size: 30px;
}
.form-control{
    height: 44px;
    border-radius: 4px;;
}
.btn{
    /* height:50px ; */
    width:100% ;
    
}
label {
    /* font-weight:400 ; */
    color:#444;
    /* text-transform:uppercase ; */
}
`
function Login() {
    const { islogin } = useContext(StateContext);

    const dispatch = useContext(DispatchContext);

    const [content,setContent]=useState(<div className="lodingD">
        <Loding/>
    </div>);
    const [lodingP,setLodingP]=useState(true);

    const navigate=useNavigate();

    const [email,setEmail]=useState("");
    const [password,setPassword]=useState("");
    const [loding,setLoding]=useState(false);


    const updateVal=(e)=>{
        switch(e.target.id){
            case "email":setEmail(e.target.value);break;
            case "password":setPassword(e.target.value);break;
            default:return;
        }
    }
    
    const handleSubmitLogin=(e)=>{
        e.preventDefault();
        if(email===""||password===""){

            return;
        }
        
        
        
        addUser();
    }
    const addUser=()=>{

        setLoding(true)
        AdminService.loginUser(email,password)
        .then(res=>{
            if(res.data.error===""){
                loginUser(dispatch,res.data.user);
                navigate("/dashboard")
                
            }else{
                toast.error(res.data.error,ToastStyle);
            }
            setLoding(false)
        }).catch(err=>{
            setLoding(false)
            toast.error(err.message,ToastStyle);
        })
    }
    const spanLoding=(
        <span className="spinner-border spinner-border-sm me-1"></span>
       )
    useEffect(()=>{
        if(islogin){
            navigate("/dashboard")
        }else{
            setLodingP(false)
        }
    },[])
    const divLogin=(
        <DivC className=" ">
           <div className="title">
                Sign in
            </div>
            <Form onSubmit={handleSubmitLogin}>
                
                <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" value={email} onChange={updateVal}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Enter password" value={password} onChange={updateVal}/>
                </Form.Group>
                
                <Button variant="warning" type="submit" className='mt-2' disabled={loding}>
                    {loding ? spanLoding:""}
                    Submit
                </Button>
                New to our platform? <Link to="/register">Create an account</Link> 
            </Form>
        </DivC>
    );
    return <>
        {lodingP?content:divLogin}
    </>
}


export default Login;
