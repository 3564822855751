import req from "../config/http-common";
import ApiConfig from "../config/ApiConfig";


const  addUser =  (dataF) => {

  var data=new FormData();
  data.append("fname",dataF.fname);
  data.append("lname",dataF.lname);
  data.append("email",dataF.email);
  data.append("password",dataF.password);
  data.append("phone",dataF.phone);
  data.append("limit_send",dataF.limit_send);
  data.append("limit_template",dataF.limit_template);
  data.append("session",true);
  return req.http.post("/admin/add_user.php",data);
};
const  getUsers =  () => {
  var data=new FormData();
  data.append("session",true);
  return req.http.post("/admin/get_users.php",data);
};

const  getUser =  (id) => {
  var data=new FormData();
  data.append("session",true);
  data.append("id",id);
  return req.http.post("/admin/get_user.php",data);
};
const  getInfo =  (id_admin) => {
  return req.http3.post("/admin/index.php",{
    "session":true,
    "id_admin":id_admin,
    "info":true
  });
};
const  signUp =  (ob) => {
  return req.http3.post("/admin/index.php",{
    ...ob,
    sign_up:true
  });
};
const signUpVerification=  (ob) => {
  return req.http3.post("/admin/index.php",{
    ...ob,
    sign_up_verification:true
  });
};
const  loginUser =  (email,password) => {
  var data=new FormData();
  data.append("session",true);
  data.append("email",email);
  data.append("password",password);
  return req.http.post("/admin/login_user.php",data);
};

const AdminService = {
  signUp,
  addUser,
  getUsers,
  getUser,
  loginUser,
  getInfo,
  signUpVerification
};

export default AdminService;