import React from 'react';

import {MdHome,MdPerson,MdEmail,MdViewList,MdAdd,MdSettings} from "react-icons/md"
import {HiTemplate} from "react-icons/hi"
import {FaTools} from "react-icons/fa"
import ln from "../Translate/"
export const  SidebarData=(lang) =>{
  return {
  "admin":[
    {
      title: ln[lang].HOME,
      path: '/dashboard',
      icon: <MdHome />,
      cName: 'nav-text',
      type:"home",
      subItem:[]
    },
    {
      title: ln[lang].USERS,
      path: '/users',
      icon: <MdPerson />,
      cName: 'nav-text',
      type:"users",
      subItem:[
        {
          title: "List Users",
          path: '/users',
          icon: <MdViewList />,
          cName: 'nav-text',
          type:"templates",
          subItem:[]
        },
        {
          title: "Add User",
          path: '/users/new_user',
          icon: <MdAdd />,
          cName: 'nav-text',
          type:"templates",
          subItem:[]
        },
      ]
    },
    {
      title: ln[lang].CLIENTS,
      path: '/clients',
      icon: <MdEmail />,
      cName: 'nav-text',
      type:"clients",
      subItem:[]
    },
    {
      title: ln[lang].TEMPLATES,
      path: '/templates',
      icon: <HiTemplate />,
      cName: 'nav-text',
      type:"templates",
      subItem:[
        {
          title: "List Templates",
          path: '/templates',
          icon: <MdViewList />,
          cName: 'nav-text',
          type:"templates",
          subItem:[]
        },
        {
          title: "Add Template",
          path: '/templates/new_template',
          icon: <MdAdd />,
          cName: 'nav-text',
          type:"templates",
          subItem:[]
        },
      ]
    },
    {
      title: "Tools",
      path: '/tools',
      icon: <FaTools />,
      cName: 'nav-text',
      type:"tools",
      subItem:[]
    },
    {
      title: "Settings",
      path: '/settings',
      icon: <MdSettings />,
      cName: 'nav-text',
      type:"settings",
      subItem:[]
    },
  ],
  "user":[
    {
      title: ln[lang].HOME,
      path: '/dashboard',
      icon: <MdHome />,
      cName: 'nav-text',
      type:"home",
      subItem:[]
    },
    {
      title: ln[lang].CLIENTS,
      path: '/clients',
      icon: <MdEmail />,
      cName: 'nav-text',
      type:"clients",
      subItem:[]
    },
    {
      title: ln[lang].TEMPLATES,
      path: '/templates',
      icon: <HiTemplate />,
      cName: 'nav-text',
      type:"templates",
      subItem:[
        {
          title: "List Templates",
          path: '/templates',
          icon: <MdViewList />,
          cName: 'nav-text',
          type:"templates",
          subItem:[]
        },
        {
          title: "Add Template",
          path: '/templates/new_template',
          icon: <MdAdd />,
          cName: 'nav-text',
          type:"templates",
          subItem:[]
        },
      ]
    },
    
    {
      title: "Settings",
      path: '/settings',
      icon: <MdSettings />,
      cName: 'nav-text',
      type:"settings",
      subItem:[]
    },
  ]};
};