import {useState,useEffect,useContext,useRef } from 'react';
import {Button,Container,Row,Col} from 'react-bootstrap'
import {MdPersonOutline,MdOutlineEmail} from "react-icons/md"
import {HiOutlineTemplate} from "react-icons/hi"
import {FiSend} from "react-icons/fi"
import AdminService from "../Service/AdminService"
import {StateContext,DispatchContext} from "../Context/"
import ln from "../Translate/"
function Settings(props) {
    const [data,setData]=useState([]);
    const { user,lang } = useContext(StateContext);
    const lan=ln[lang];
    const dispatch = useContext(DispatchContext);
    useEffect(()=>{
      
    },[])

 
  
     
    return (
    <Container fluid="md" className="bx-sh mb-3 pb-3">
        setting
    </Container>
        
    );
}

export default Settings;