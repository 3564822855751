import {useState,useEffect,useContext,useRef } from 'react';
import {Button,Form,Container,Row,Col} from 'react-bootstrap'
import {RiDeleteBinLine,RiAddLine} from "react-icons/ri"
import {TableStyle} from "../Them/"
import DataTable from 'react-data-table-component';
import SocialMediaService from '../Service/SocialMediaService';
import ApiConfig from "../config/ApiConfig"
import styled from 'styled-components';
import FormAddSocial from './FormAddSocial';
const DivC = styled.div`
.trb{
    animation: trb .3s;
   
}
@keyframes trb{
    from{
       
    }
    to{
      
    }
}
`
function Social(props) {
    const [data,setData]=useState([])
    const [addView,setAddView]=useState(false);
    useEffect(()=>{
      getData();
    },[])
    const getData=()=>{
        SocialMediaService.getAll().
        then(res=>{
            setData(res.data)
        })
    }
    const columns = [
       
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            grow:2
        },
        {
            name: 'Link',
            selector: row => row.link,
            sortable: true,
            grow:2
        },
        {
            name: 'Logo',
            selector: row => <>
                <img src={`${ApiConfig.url+"social-media/logo/"+row.logo}`} width="24" height="24" alt="" />
            </>,
            sortable: true,
            grow:2
        },
        {
            name: '',
            selector: row => 
            <button className='btn btn-light text-danger btn-effect'>
                
                <RiDeleteBinLine/>
            </button>,
            grow:"none"
        },
    ]; 
    return (
        <DivC>
    <Container>
        <Row>
            <Col className=' d-flex flex-row-reverse mb-2'>
                    
                <button className='btn btn-default ' onClick={()=>setAddView(!addView)}>
                    <span className='me-1'><RiAddLine/></span>
                        add Media
                </button>
                
            </Col>
        </Row>
        {
            addView&&(
            <Row className="trb border p-3">
                <Col >
                <FormAddSocial getData={getData}/>
                </Col>
            </Row>
            )
        }
        <Row>
            <Col>
                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    customStyles={TableStyle}
                    sortable
                />
            </Col>
        </Row>
    </Container>
    </DivC>
        
    );
}

export default Social;