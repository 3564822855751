import {useState,useEffect,useContext} from 'react';
import {RiFileListLine,RiDeleteBinLine,RiAddLine} from "react-icons/ri"
import {MdOutlineModeEditOutline} from "react-icons/md"
import {TiThListOutline} from "react-icons/ti"
import { Link ,useLocation,useNavigate} from 'react-router-dom';
import {TableStyleTemp,ToastStyle} from "../Them/"
import ContactService from "../Service/ContactService"
import TemplateService from "../Service/TemplateService"
import {StateContext} from "../Context/"
import {Container,Col,Row} from "react-bootstrap"
import Modal from "../Modal/"
import {toast} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import {MdKeyboardBackspace} from "react-icons/md"
import DatePicker from "react-datepicker";
import {Form} from 'react-bootstrap'
import{MdDateRange} from "react-icons/md"

const Send = () => {
    const [pending, setPending] = useState(true);
    const [data,setData]=useState([])
    const { user,lang } = useContext(StateContext);
    const [date,setDate]=useState(new Date());
    const navigate=useNavigate()
    const location=useLocation()
    const [datar,setDatar]=useState(location.state);
    const [step,setStep]=useState(1)
    const [itemD,setItemD]=useState(null)
    const [loding,setLoding]=useState(false)
    const [showModalDelete,setShowModalDelete]=useState(false);
    const spanLoding=(
        <span className="spinner-border spinner-border-sm me-1"></span>
    )
   
    const getData =  () => {
        ContactService.getList(user.id)
        .then(res=>{

            setData(
                res.data.data.map(el=>{
                    return {...el,checked:false}
                })
            ) ;
            setPending(false)   
        }).catch(err=>{
            setPending(false)  
        })
    }
    useEffect(()=>{
        getData()
    },[])
  
    const updateVal=(key)=>{
        setData(data.map((el,i)=>{
            return {...el,checked:key==i?!el.checked:el.checked}
        }))
    }
    const changeStep=(v)=>{
        setStep(v)
    }
    



    
    const handleSubmit=(e)=>{
        e.preventDefault();
        
        let t=[];
        let el=data.map(el=>{
            if(el.checked)
                t.push(el.id_list);
            return null;
        })
        
        
        addMail({id_temp:datar.id,list_ids:t.join("-"),date:date.toISOString().replace("T"," ").slice(0,19)});
    }
    const addMail=(ob)=>{

        setLoding(true)
        TemplateService.sendEmails(ob)
        .then(res=>{
            setLoding(false);
            if(res.data.error==""){
                changeStep(3)
                toast.success('Send success',ToastStyle);
            }else{
                toast.error(res.data.error,ToastStyle);
            }
        }).catch(err=>{
            setLoding(false)
            toast.error(err.message,ToastStyle);
        })
    }
    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <div className='col-12 p-2 d-flex justify-content-end'>
                    
                    <button className='btn btn-default ' onClick={()=>navigate("/templates")}>
                     <span className='me-1'><MdKeyboardBackspace/></span>
                        back
                        
                    </button>
                </div>
            </Row>
            
            {
                step==1&&(
                    <Row>
                        <Col >
                            <h5>chose lists</h5>
                        </Col>
                        <Col sm={12}>
                            <ul class="list-group">
                            {
                                pending?"loding":(
                                    data.map((el,key)=>{
                                        return el.num==0?"":( 
                                        <li className={`list-group-item d-flex align-items-center ${el.checked?"bg-light":""}`} key={key}>
                                            <input type="checkbox" checked={el.checked} className="custom-control-input me-3"  onChange={()=>updateVal(key)}/>
                                            <span className="flex-grow-1">{el.name_list}</span>
                                            <strong >{el.num}</strong>
                                        </li>)
                                    })
                                )
                            }
                            </ul> 
                        </Col>
                        <Col className="d-flex justify-content-end mt-2">
                            <button className="btn btn-primary" onClick={()=>changeStep(2)}>Next </button>
                        </Col>
                    </Row>
                )
            }
            {
                step==2&&(
                    <Row>
                        <Col sm={12}>
                            <Form.Group className="mb-3 mt-2"  >
                                <Form.Label> <MdDateRange/> Date send this template</Form.Label>
                                <DatePicker
                                    id="date" 
                                    className='form-control' 
                                    showTimeSelect 
                                    min={ Date.now()} 
                                    minDate={Date.now()}
                                    dateFormat="yyyy-MM-dd HH:mm:00" 
                                    selected={date} 
                                    onChange={(dates) => {setDate(dates)}}
                                    timeFormat="HH:mm"
                                    
                                    />
                            </Form.Group>
                        </Col>
                        <Col className="d-flex justify-content-end mt-2">
                            <button className="btn btn-primary me-2" onClick={()=>changeStep(1)}>Previos </button>
                            <button className="btn btn-primary" type="submit" disabled={loding}>
                                {loding ? spanLoding:""}
                                Send 
                            </button>
                        </Col>
                    </Row>
                )
            }
             {
                step==3&&(
                <Row>
                    <Col className=''>
                    <div class="alert alert-success">
                        <strong>Success!</strong>sent succesfully.
                    </div>
                    </Col>
                </Row>
                )
            }
        </Form>
    );
};

export default Send;