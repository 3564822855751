import React from 'react';
import {Button,Container,Row,Col} from 'react-bootstrap'
import styled from 'styled-components';

const DivC = styled.div`
.card{
    border:none;
    min-height: 100px;
    margin-top: 14px;
    border-radius: 0.75rem;;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
}
.card hr{
    background:linear-gradient(45deg,transparent ,#555,transparent)
}
.info{
    display: flex;
    -moz-box-pack: justify;
    justify-content: space-between;
    padding-top: 8px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    background: transparent none repeat scroll 0% 0%;
    color: rgb(52, 71, 103);
    box-shadow: none;
}
.card .logo{
    font-size: 50px;
}
.info h4{
    font-weight: bold;
    font-size:  1.5rem;
    letter-spacing: 0.00735em;
    color:rgb(52, 71, 103);
}
.info p{
    color: rgb(123, 128, 154);
    font-size:0.875rem;
    font-weight: 300!important;
}
.det{
    margin: 0px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 0.02857em;
    display: flex;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(123, 128, 154);
}
.det span{
    margin: 0px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 0.875rem;
    line-height: 1.5;
    letter-spacing: 0.02857em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(76, 175, 80);
    font-weight: 700;
}
`
function Stat(props) {
    return (
        <DivC className="col-lg-3 col-md-6 col-sm-12">
            <div  className="card m-2 p-3">
                <div className="logo text-center " style={{color:`${props.color}`}}>
                    {props.icon}
                </div>
                <hr />
                <div className='d-flex flex-column info'>
                    <p className="text-end m-0 text-center">{props.title}</p>
                    <h4 className="p-0 text-end m-0 text-center">{props.number}</h4>
                </div>
                
                <p className="det">
                    <span className="me-1">+{props.newNumber} </span>
                 
                </p>
            </div>
        </DivC>
    );
}

export default Stat;