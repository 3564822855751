import {useState,useEffect,useContext} from 'react';
import {RiFileListLine,RiDeleteBinLine,RiAddLine} from "react-icons/ri"
import {MdOutlineModeEditOutline} from "react-icons/md"
import {TiThListOutline} from "react-icons/ti"
import { Link ,useLocation,useNavigate} from 'react-router-dom';
import DataTable  from 'react-data-table-component'
import {TableStyleTemp,ToastStyle} from "../Them/"
import ContactService from "../Service/ContactService"
import {StateContext} from "../Context/"
import {Container,Col,Row} from "react-bootstrap"
import Modal from "../Modal/"
import {toast} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import Tooltip from '../Them/Tootlip';
const ListContacts = () => {
    const [pending, setPending] = useState(true);
    const [data,setData]=useState([])
    const { user,lang } = useContext(StateContext);
    const navigate=useNavigate()
    const [itemD,setItemD]=useState(null)
    const [lodingUpload,setLodingUpload]=useState(false)
    const [showModalDelete,setShowModalDelete]=useState(false);
    const spanLoding=(
        <span className="spinner-border spinner-border-sm me-1"></span>
    )
    const showModalDeleteF=(id)=>{
        setItemD(id)
        setShowModalDelete(true);
    }
    const hideModalDeleteF=()=>{
        setLodingUpload(false)
        setItemD(null)
        setShowModalDelete(false);
    }
    const deleteList =  () => {
        setLodingUpload(true)
        ContactService.deleteList(itemD)
        .then(res=>{
            if(res.data.error==""){
                toast.success('Delete success',ToastStyle);
                getData()
            }else{
                toast.error(res.data.error,ToastStyle);
            }
            hideModalDeleteF()
        }).catch(err=>{
            hideModalDeleteF()
            toast.error(err.message,ToastStyle);
        })
    }

    const getData =  () => {
        ContactService.getList(user.id)
        .then(res=>{

            setData(res.data.data) ;
            setPending(false)   
        }).catch(err=>{
            setPending(false)  
        })
    }
    useEffect(()=>{
        getData()
    },[])
    const columns = [
        {
            name: 'id',
            selector: row => row.id_list,
            grow:"1"
        },
        {
            name: 'Name',
            selector: row => row.name_list,
            grow:"1"
        },
        {
            name: 'Creation',
            selector: row => row.date_add,
            grow:"1"
        },
        {
            name: 'Contacts',
            selector: row => row.num,
            grow:"1"
        },
        {
            name: '',
            selector: row =><Container>
                <Row>
                    <Col>
                        <button data-tip="show contacts"  className='btn btn-light text-dark btn-effect me-2' onClick={()=>navigate("/clients/contacts",{state:row})}> 
                            <TiThListOutline/>
                            <Tooltip/>
                        </button>
                        <button onClick={()=>navigate("/clients/update_list",{state:row})} data-tip="edit" className='btn btn-light text-primary btn-effect me-2'>
                            <MdOutlineModeEditOutline/>
                            <Tooltip/>
                        </button>
                        <button data-tip="delete" className='btn btn-light text-danger btn-effect' onClick={()=>showModalDeleteF(row.id_list)} >
                            <RiDeleteBinLine/>
                            <Tooltip/>
                        </button>
                    </Col>
                </Row>
            </Container>,
            allowOverflow:true,
            right:true
        }
    ]
    return (
        <>
             <div className='row'>
                <div className='col-12 p-2 d-flex'>
                    
                    <button className='btn btn-success ' onClick={()=>navigate("/clients/add_list",{state:user})}>
                        <span className='me-1'><RiAddLine/></span>
                        Add List
                        
                    </button>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={data}
                pagination
                customStyles={TableStyleTemp}
                sortable
                progressPending={pending} 
            />
            <Modal showModal={showModalDelete}>
                <h4 className=" p-3 text-center border-bottom mb-4">Are you sure to delete this item?</h4>
                <div className='col-sm-12 d-flex'>
                    <button className='btn btn-danger flex-grow-1 me-4' onClick={deleteList}>
                        {
                                lodingUpload&&(
                                    spanLoding 
                                )
                        }
                        Yes
                    </button>
                    <button className='btn btn-primary flex-grow-1' onClick={hideModalDeleteF}>Cancel</button>
                </div> 
            </Modal>
        </>
    );
};

export default ListContacts;