

export const TableStyle= {
    headCells: {
        style: {
            fontSize: '14px',
            fontWeight:"900",
            fontFamily:"Roboto",
            color:"#222"
        },
    },
    cells: {
        style: {
            fontSize: '14px',
            color:"#444"
        },
    },
}
export const TableStyleTemp= {
    headCells: {
        style: {
            fontSize: '14px',
            fontWeight:"900",
            fontFamily:"Roboto",
            color:"#222"
        },
    },
    cells: {
        style: {
            fontSize: '14px',
            color:"#444"
        },
    },
}
export const ToastStyle={
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
}
