
import React, { useState,useContext } from 'react';
import { Link,useNavigate,useLocation,useParams } from 'react-router-dom';
import Lang from './Lang';
import ln from "../Translate/"
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import {DispatchContext,logout,StateContext} from "../Context/"
import { SidebarData } from './SidebarData';
import { FaTachometerAlt, FaGem, FaList, FaGithub, FaRegLaughWink, FaHeart } from 'react-icons/fa';
// import 'react-pro-sidebar/dist/css/styles.css';
import logo from "../images/logo.svg"
import logo_text from "../images/logo_text.png"

const SideBarPro = ({ collapsed, rtl, toggled, handleToggleSidebar }) => {
  const { user ,lang} = useContext(StateContext);

  const lan=ln[lang];
  const navigate=useNavigate()
  const wegosend_logo=(
    <img src={logo_text} height="30" alt="" />
  )
let location = useLocation();
  return (
    <ProSidebar
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
      rtl={rtl=="rtl"}
    >
      <SidebarHeader>
        <div
          className='d-flex align-items-center justify-content-center'
          style={{
            height: '60px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 20,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <img src={logo} alt="" width="30" height="30" className='me-2'/>
          {!collapsed&&wegosend_logo}
        </div>
      </SidebarHeader>

      <SidebarContent className='mt-3'>
        <Menu iconShape="circle">
          {
            SidebarData(lang)[user.type==0?"user":"admin"].map(
              (item, index) => {
                  return (
                    item.subItem.length==0?(
                        <MenuItem
                          icon={item.icon}
                          active={("/"+location.pathname.split("/")[1])==("/"+item.path.split("/")[1])?true:false}
                          
                        >
                          <Link to={item.path}  onClick={()=>handleToggleSidebar(false)}>
                          {item.title}
                          </Link>
                        </MenuItem>
                    ):(
                      <SubMenu
                        title={item.title}
                        icon={item.icon}
                        className={("/"+location.pathname.split("/")[1])==("/"+item.path.split("/")[1])?"sub-active":""}
                      >
                        {
                        item.subItem.map((el,key)=>{
                          return(
                            <MenuItem
                            icon={el.icon}
                            className={(location.pathname==el.path)?"sub-menu-active":""}
                            >
                              <Link to={el.path} onClick={()=>handleToggleSidebar(false)}>
                              {el.title}
                              </Link>
                            </MenuItem>
                          )
                        })
                        }
                      </SubMenu>
                    )
                      
                  );
              }
            )
            }
        </Menu>
      </SidebarContent>

      <SidebarFooter style={{ textAlign: 'center' }}>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: '20px 24px',
          }}
        >
          
          <Link to="/upgrade" onClick={()=>handleToggleSidebar(false)} className="btn btn-primary btnu">
          upgrade
          </Link>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default SideBarPro;










































{/* <SidebarContent>
        <Menu iconShape="circle">
          <MenuItem
            icon={<FaTachometerAlt />}
            suffix={<span className="badge red">new</span>}
          >
            dashboard
          </MenuItem>
          <MenuItem icon={<FaGem />}>components</MenuItem>
        </Menu>
        <Menu iconShape="circle">
          <SubMenu
            suffix={<span className="badge yellow">3</span>}
            title={ 'withSuffix' }
            icon={<FaRegLaughWink />}
          >
            <MenuItem>submenu 1</MenuItem>
            <MenuItem>submenu 2</MenuItem>
            <MenuItem>submenu 3</MenuItem>
          </SubMenu>
          <SubMenu
            prefix={<span className="badge gray">3</span>}
            title={'withPrefix'}
            icon={<FaHeart />}
          >
            <MenuItem>submenu 1</MenuItem>
            <MenuItem>submenu 2</MenuItem>
            <MenuItem>submenu 3</MenuItem>
          </SubMenu>
          <SubMenu title={'multiLevel'} icon={<FaList />}>
            <MenuItem>submenu 1 </MenuItem>
            <MenuItem>submenu 2 </MenuItem>
          </SubMenu>
        </Menu>
      </SidebarContent> */}