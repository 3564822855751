import {useState,useEffect,useContext} from 'react';

import { Link ,useLocation} from 'react-router-dom';
import ListTemplates from './ListTemplates';
import NewTemplate from './NewTemplate';
import Send from './Send';
import Stat from './Stat';
import {Container } from "react-bootstrap"


function Templates(props) {


  
    const getView=(v)=>{
        switch(v){
            case "/templates" : return <ListTemplates/>;
            case "/templates/new_template" : return <NewTemplate update={false}/>;
            case "/templates/send" : return <Send/>;
            case "/templates/statistics" : return <Stat/>;
            
            default : return <NewTemplate update={true}/>;
        }
        return null;
    }


    const location=useLocation()
    
    return (
        <Container fluid="md" className="bx-sh mb-3 pb-3">
            {
                getView(location.pathname)
            }
        </Container>
    );
}

export default Templates;