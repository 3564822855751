import {useState,useEffect,useContext,useRef } from 'react';
import {Button,Container,Row,Col} from 'react-bootstrap'
import {MdPersonOutline,MdOutlineEmail} from "react-icons/md"
import {HiOutlineTemplate} from "react-icons/hi"

import {AiOutlineCheck} from "react-icons/ai"
import AdminService from "../Service/AdminService"
import {StateContext,DispatchContext} from "../Context/"
import styled from 'styled-components'; 
import ln from "../Translate/"


const DivC = styled.div`
.tarif{
    margin-top: 1.5em;
}
.tarif .price{
    font-weight: bold;
}
.tarif>div{
    height:100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
    border-radius:5px;
    background-color: #FFF;
}
.tarif h4,.tarif h3,.tarif p,.tarif .price{
    text-align:center;
    font-family:Syne
}
.tarif .title{
    font-weight: 900;
}
.tarif hr{
    background:linear-gradient(45deg,transparent ,#555,transparent)
}
ul li{
    font-family:Syne
}
ul{
    padding:0;
    list-style-type: none;
}
.btn {
    box-shadow: none!important;
}
.header{
    text-align:center
}
.header h4{
    font-size: 50px;
    font-weight: 900 !important;
}
.header p{
    font-size:20px;
}
`
function Upgrade(props) {
    const [data,setData]=useState([]);
    const { user,lang } = useContext(StateContext);
    const lan=ln[lang];
    const dispatch = useContext(DispatchContext);
    useEffect(()=>{
      
    },[])

 
    const header=(props.header=="no")?false:true
     
    return (
    <DivC>
    <Container fluid="md" className="bx-sh mb-3 p-4 transparent">

        {
            header?
            <Row className="ps-5">
                <Col className="ps-5">
                    <h4 className="m-0">Choisissez l'offre dont vous avez besoin!</h4>
                    <p>
                        Besoin d'envoyer ponctuellement ou régulièrement des emails? Découvrez nos offres
                    </p>
                </Col>
            </Row>
            :
            <Row className="">
                <Col className="ex text-center"  >
                    <h1 className='ps-6'>Choisissez l'offre dont vous avez besoin!</h1>
                    <p className=' mt-2'>
                    Besoin d'envoyer ponctuellement ou régulièrement des emails? Découvrez nos offres
                    </p>    
                </Col>
            </Row>
            
        }
        <Row className='mt-3'>
            
            <Col className='tarif' lg={3} md={6} sm={12}>
                <div className="d-flex flex-column p-2">
                    <h3 className="mb-4 title">Free</h3>
                    <hr className="mt-0"/>
                    <h4 className="m-0 pb-0">5000 Emails</h4>
                    <p className="p-0">
                        Volume mensuel
                    </p>
                    <ul className="mt-2 ps-2">
                        <li className="d-flex align-items-stretch">
                            <div className="text-success">
                                <AiOutlineCheck className="me-4"/>
                            </div>
                            volume d'emails sur mesure 
                        </li>
                        <li className="d-flex align-items-stretch mt-1">
                            <div className="text-success">
                                <AiOutlineCheck className="me-4"/>
                            </div>
                            Contacts illimités
                        </li>
                    </ul>
                    <div className="flex-grow-1">
                    </div>
                    <h5 className="price">
                        0 &euro; 
                    </h5>
                    <button className="btn btn-warning">
                        Commander
                    </button>
                </div>
            </Col>
            
            <Col className='tarif' lg={3} md={6} sm={12}>
                <div className="d-flex flex-column p-2">
                    <h3 className="mb-4 title ">Bronze</h3>
                    <hr className="mt-0"/>
                    <h4 className="m-0 pb-0">100 000 Emails</h4>
                    <p className="p-0">
                        Volume mensuel
                    </p>
                    <ul className="mt-2 ps-2">
                        <li className="d-flex align-items-stretch">
                            <div className="text-success">
                                <AiOutlineCheck className="me-4"/>
                            </div>
                            volume d'emails sur mesure 
                        </li>
                        <li className="d-flex align-items-stretch mt-1">
                            <div className="text-success">
                                <AiOutlineCheck className="me-4"/>
                            </div>
                            Contacts illimités
                        </li>
                        <li className="d-flex align-items-stretch mt-1">
                            <div className="text-success">
                                <AiOutlineCheck className="me-4"/>
                            </div>
                            Assistance par email
                        </li>
                        <li className="d-flex align-items-stretch mt-1">
                            <div className="text-success">
                                <AiOutlineCheck className="me-4"/>
                            </div>
                            Accés multi-utilisateurs
                        </li>
                        <li className="d-flex align-items-stretch mt-1">
                            <div className="text-success">
                                <AiOutlineCheck className="me-4"/>
                            </div>
                            Suppression le logo wegosend
                        </li>
                        <li className="d-flex align-items-stretch mt-1">
                            <div className="text-success">
                                <AiOutlineCheck className="me-4"/>
                            </div>
                            Rapport avancé
                        </li>
                    </ul>
                    <div className="flex-grow-1">
                    </div>
                    <h5 className="price">
                        49 &euro; /mois
                    </h5>
                    <button className="btn btn-warning">
                        Commander
                    </button>
                </div>
            </Col>



            <Col className='tarif' lg={3} md={6} sm={12}>
                <div className="d-flex flex-column p-2">
                    <h3 className="mb-4 title">Silver</h3>
                    <hr className="mt-0"/>
                    <h4 className="m-0 pb-0">250 000 Emails</h4>
                    <p className="p-0">
                        Volume mensuel
                    </p>
                    <ul className="mt-2 ps-2">
                        <li className="d-flex align-items-stretch">
                            <div className="text-success">
                                <AiOutlineCheck className="me-4"/>
                            </div>
                            volume d'emails sur mesure 
                        </li>
                        <li className="d-flex align-items-stretch mt-1">
                            <div className="text-success">
                                <AiOutlineCheck className="me-4"/>
                            </div>
                            Contacts illimités
                        </li>
                        <li className="d-flex align-items-stretch mt-1">
                            <div className="text-success">
                                <AiOutlineCheck className="me-4"/>
                            </div>
                            Assistance par email
                        </li>
                        <li className="d-flex align-items-stretch mt-1">
                            <div className="text-success">
                                <AiOutlineCheck className="me-4"/>
                            </div>
                            Accés multi-utilisateurs
                        </li>
                        <li className="d-flex align-items-stretch mt-1">
                            <div className="text-success">
                                <AiOutlineCheck className="me-4"/>
                            </div>
                            Suppression le logo wegosend
                        </li>
                        <li className="d-flex align-items-stretch mt-1">
                            <div className="text-success">
                                <AiOutlineCheck className="me-4"/>
                            </div>
                            Rapport Pro
                        </li>
                    </ul>
                    <div className="flex-grow-1">
                    </div>
                    <h5 className="price">
                        79 &euro; /mois
                    </h5>
                    <button className="btn btn-warning">
                        Commander
                    </button>
                </div>
            </Col>


            <Col className='tarif' lg={3} md={6} sm={12}>
                <div className="d-flex flex-column p-2">
                    <h3 className="mb-4 title">Gold</h3>
                    <hr className="mt-0"/>
                    <h4 className="m-0 pb-0">250 000 Emails</h4>
                    <p className="p-0">
                        Volume mensuel
                    </p>
                    <ul className="mt-2 ps-2">
                        <li className="d-flex align-items-stretch">
                            <div className="text-success">
                                <AiOutlineCheck className="me-4"/>
                            </div>
                            volume d'emails sur mesure 
                        </li>
                        <li className="d-flex align-items-stretch mt-1">
                            <div className="text-success">
                                <AiOutlineCheck className="me-4"/>
                            </div>
                            Contacts illimités
                        </li>
                        <li className="d-flex align-items-stretch mt-1">
                            <div className="text-success">
                                <AiOutlineCheck className="me-4"/>
                            </div>
                            Assistance par email
                        </li>
                        <li className="d-flex align-items-stretch mt-1">
                            <div className="text-success">
                                <AiOutlineCheck className="me-4"/>
                            </div>
                            Accés multi-utilisateurs
                        </li>
                        <li className="d-flex align-items-stretch mt-1">
                            <div className="text-success">
                                <AiOutlineCheck className="me-4"/>
                            </div>
                            Suppression le logo wegosend
                        </li>
                        <li className="d-flex align-items-stretch mt-1">
                            <div className="text-success">
                                <AiOutlineCheck className="me-4"/>
                            </div>
                            Rapport Evo
                        </li>
                    </ul>
                    <div className="flex-grow-1">
                    </div>
                    <h5 className="price">
                        139 &euro; /mois
                    </h5>
                    <button className="btn btn-warning">
                        Commander
                    </button>
                </div>
            </Col>


            
        </Row>
    </Container>
    </DivC>
    );
}

export default Upgrade;